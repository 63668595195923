/**
 * https://developers.facebook.com/docs/meta-pixel/reference
 */

export const trackFBPageViewEvent = () => {
  window.fbq?.('track', 'PageView')
}

export interface IFBCompleteRegistrationEventParams {
  currency?: unknown
  value?: unknown
}
export const trackFBCompleteRegistrationEvent = (
  params?: IFBCompleteRegistrationEventParams
) => {
  window.fbq?.('track', 'CompleteRegistration', params)
}

export interface IFBPurchaseEventParams {
  content_ids?: unknown
  content_type?: unknown
  contents?: unknown
  num_items?: unknown
  currency: unknown
  value: unknown
}
export const trackFBPurchaseEvent = (params: IFBPurchaseEventParams) => {
  window.fbq?.('track', 'Purchase', params)
}

declare global {
  interface Window {
    fbq?: (...args: unknown[]) => void
  }
}
