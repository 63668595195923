import React, { useMemo, useState } from 'react'

import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import { SearchInput } from '@/components/Message/components/SearchInput'
import { Personal } from '@/components/Personal'
import { useMessageAtom } from '@/store/message'
import DelSrc from '@img/components/del2.png'

import styles from '../message.module.less'

interface IProps {
  change: (step: string) => void
  userIds: number[]
  setUserIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

export const CreateGroupStep1: React.FC<IProps> = (props) => {
  const { change, userIds, setUserIds } = props
  const { setCreateCroup, userFriends } = useMessageAtom()
  const [searchVal, setSearchVal] = useState('')

  const userList = useMemo(() => {
    const sortData = userFriends
      .filter(
        (item) => !['system', 'assistant'].includes(String(item.follower?.id))
      )
      .sort(
        (a, b) =>
          a.follower?.nickname.localeCompare(b.follower?.nickname || '') || 0
      )

    letters.forEach((preFix) => {
      const index = sortData.findIndex(
        (data) => data.follower?.nickname.toLocaleUpperCase()[0] === preFix
      )
      if (index !== -1) {
        sortData[index].preFix = preFix
      }
    })

    return sortData
  }, [userFriends])

  const handleSelect = (id: number) => {
    if (userIds.includes(id)) {
      setUserIds(userIds.filter((item) => item !== id))
    } else {
      setUserIds([...userIds, id])
    }
  }

  return (
    <>
      <div className="step1-left">
        <div className="search-box">
          <SearchInput
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
          />
        </div>
        <div className={`${styles.ContactList} scroll-box`}>
          <ul>
            {userList.map((item) => {
              return (
                <div
                  key={item.follower_id}
                  className={`${
                    item.follower?.nickname.indexOf(searchVal) !== -1
                      ? ''
                      : 'g-hidden'
                  }`}
                  onClick={() => {
                    handleSelect(item.follower_id as number)
                  }}
                >
                  {item.preFix && <div className="letter">{item.preFix}</div>}
                  <li>
                    <Checkbox
                      className="ml4 mr10 c-radio"
                      value={item.follower_id}
                      checked={userIds.includes(item.follower_id || NaN)}
                    />
                    <Personal
                      nickname={item.follower?.nickname}
                      userData={item.follower}
                    />
                  </li>
                </div>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="step1-right">
        <div className={`${styles.ContactList} scroll-box`}>
          <ul>
            {userList.map((item) => {
              return userIds.includes(item.follower_id || NaN) ? (
                <li key={item.follower_id}>
                  <Personal
                    nickname={item.follower?.nickname}
                    userData={item.follower}
                  />
                  <a>
                    <img
                      src={DelSrc}
                      width={18}
                      onClick={() => {
                        setUserIds(
                          userIds.filter(
                            (userId) => userId !== item.follower_id
                          )
                        )
                      }}
                    />
                  </a>
                </li>
              ) : null
            })}
          </ul>
        </div>
        {/* 按钮 */}
        <div className="footer">
          <button className="g-btn mr20" onClick={() => setCreateCroup(false)}>
            Cancel
          </button>
          <button
            className="g-btn g-btn-primary mr20"
            onClick={() => change('step2')}
          >
            Next
          </button>
        </div>
      </div>
    </>
  )
}
