import { useLocation } from 'react-router-dom'

import { IStoryListItem } from '@/httpRequest/modules/novel/list'
import Message from '@img/home/message2.png'
import draft from '@img/personal/drafts2.png'

import styles from '../waterfall.module.less'

export interface IConfig {
  messageType: string
  title: string
  subTitle: string
  url: string
}
interface Props {
  actionEl?: JSX.Element | null

  config?: IConfig

  info?: IStoryListItem

  handleRead?: () => void
}

export const Discuss: React.FC<Props> = (props) => {
  const { actionEl, info, handleRead } = props
  const location = useLocation()
  return (
    // 置顶样式 styles.Pinned
    <div
      className={`${styles.waterfallDiscussCard} waterfallDiscussCard ${
        info?.is_author_pinned || info?.is_pinned ? styles.Pinned : ''
      }`}
      onClick={() => {
        if (!location.pathname.includes('personal')) {
          handleRead?.()
        }
      }}
    >
      <div className="hover">
        <img src={Message} width={108} className="messageIcon" />

        <div className="title">
          <div className="mle">{info?.title}</div>
        </div>
        {/* <div
          className="sub-title"
          dangerouslySetInnerHTML={{ __html: info?.content || '' }}
        ></div> */}

        {/* 操作按钮 */}
        {actionEl}
      </div>

      {/* 标签 */}
      <div className="tags">
        {/* 草稿 */}
        {info?.status === 1 ? (
          <a className="draft">
            <button>
              <img src={draft} />
              DRAFT
            </button>
            <span>DRAFT (only visible to you)</span>
          </a>
        ) : null}
      </div>
    </div>
  )
}
