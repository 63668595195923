import React from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { Image } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { MessageEnum } from '@/components/Message/Types'
import { Personal } from '@/components/Personal'
import { Books } from '@/components/Waterfall/Items/Books'
import { Discuss, IConfig } from '@/components/Waterfall/Items/Discuss'
import { Story } from '@/components/Waterfall/Items/Story'
import { VideoItem } from '@/components/Waterfall/Items/VideoItem'
import { IStoryListItem } from '@/httpRequest/modules/novel/list'
import { IListRellItem } from '@/httpRequest/modules/user/listReel'
import { EPublishType } from '@/pages/Home/Types'
import { useMessageAtom } from '@/store/message'

import styles from '../message.module.less'

export enum ENotificationType {
  GO_TO_SIGN = 1,
  CHECK_IT = 2
}

interface Props {
  type: string
  self: boolean
  data?: RongIMLib.IAReceivedMessage
  config?: IConfig
  extra?: unknown
  userData?: {
    nickname?: string
  }
}

export const MessageItem: React.FC<Props> = (props) => {
  const { type, self, data, config, extra, userData = {} } = props
  const { nickname } = userData

  const navigate = useNavigate()
  const { toggleMessageModalVisible } = useMessageAtom()

  const blackBgMsg = [MessageEnum.Text, MessageEnum.Reply]
  const handleRead = () => {
    const sourceInfo = extra as Record<string, string>
    if (!sourceInfo) return
    toggleMessageModalVisible(false)
    if (type === MessageEnum.Books) {
      navigate(`/detail/book/${EPublishType.Lists}-${sourceInfo.resourceId}`)
      return
    }

    if (type === MessageEnum.Discuss) {
      navigate(
        `/detail/discuss/${EPublishType.Community}-${sourceInfo.resourceId}`
      )
      return
    }
    navigate(`/cover/${sourceInfo.sourceType}-${sourceInfo.resourceId}`)
  }

  const handleJumpTo = (link: string) => {
    if (!link) return

    toggleMessageModalVisible(false)
    navigate(link)
  }

  return (
    <div
      className={`MessageItem ${self ? 'MessageItemSelf' : ''}`}
      id={String(data?.messageUId)}
    >
      <div style={{ textAlign: 'center', color: 'white' }}>
        {dayjs(data?.sentTime).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      {/* 名称 */}
      {nickname && <div className="message-name">{nickname}</div>}
      <div className="message-line">
        {/* 消息 */}
        <div
          className={`message-box ${
            blackBgMsg.includes(type as MessageEnum) ? 'message-box-black' : ''
          }`}
          onClick={() => {
            if (config?.url) {
              window.location.href = config?.url as string
            }
          }}
        >
          {/* 文本消息 */}
          {type === MessageEnum.Text && (
            <div className="message-text">
              {data?.content.content
                .split?.('<br>')
                .map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                )) || data?.content.content}
            </div>
          )}

          {/* 图片消息 */}
          {type === MessageEnum.Image && (
            <div className="message-media">
              <Image src={data?.content.imageUri} width={185} />
            </div>
          )}

          {/* 讨论消息 */}
          {type === MessageEnum.Discuss && (
            <div
              className={`message-media ${styles.messageDiscuss}`}
              onClick={handleRead}
            >
              <div className="">
                <Discuss config={config} info={extra as IStoryListItem} />
              </div>
            </div>
          )}

          {/* 故事消息 */}
          {type === MessageEnum.Story && (
            <div
              className={`message-media ${styles.messageStory}`}
              onClick={handleRead}
            >
              <div className="">
                <Story info={extra as IStoryListItem} />
              </div>
            </div>
          )}

          {/* 视频消息 */}
          {type === MessageEnum.Video && (
            <div
              className={`message-media ${styles.messageVideo}`}
              onClick={handleRead}
            >
              <div className="">
                <VideoItem info={extra as IListRellItem} />
              </div>
            </div>
          )}

          {/* 书单消息 */}
          {type === MessageEnum.Books && (
            <div
              className={`message-media ${styles.messageBooks}`}
              onClick={handleRead}
            >
              <div className="">
                <Books info={extra as IStoryListItem} />
              </div>
            </div>
          )}

          {/* 回复消息 */}
          {type === MessageEnum.Reply && (
            <div className="message-reply">
              <Personal size={'small'} userData={{}} />
              <div className="c-white ml20">
                “Gran trabajo, cohesionado a la perfección yun concepto gráfico
                muy interesante. ”
              </div>
            </div>
          )}
        </div>

        {data?.content?.extra?.notification_type ? (
          <span
            className="btn-tag"
            onClick={() => {
              handleJumpTo(data?.content?.extra?.link || '')
            }}
          >
            {data?.content?.extra?.button_name}
          </span>
        ) : null}
      </div>
    </div>
  )
}

export const MessageTime = ({ time }: { time: string }) => {
  return (
    <div className="MessageTime">
      <span>{time}</span>
    </div>
  )
}
