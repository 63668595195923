import { useEffect, useMemo, useRef, useState } from 'react'

import {
  ConversationType,
  IAReceivedConversation,
  IAReceivedMessage
} from '@rongcloud/imlib-next'
import { Divider, List, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'

import { IConfig } from '@/components/Waterfall/Items/Discuss'
import { isSuccess } from '@/httpRequest'
import {
  IGroupMemberItem,
  getGroupMembers
} from '@/httpRequest/modules/imInfo/groupMembers'
import { useMessageAtom } from '@/store/message'
import { useUserAtom } from '@/store/user'

import { MessageItem } from './MessageItem'
import { MessageEnum } from '../Types'
import { ERongCloudMessageType, getHistoryMessages } from '../utils'

export interface IChatPanelContainerRef {
  scrollToBottom: () => void
}

export const ChatPanelContainer = () => {
  const [loading, setLoading] = useState(false)
  const {
    messageData,
    setMessageData,
    currentConversation,
    members,
    setMembers
  } = useMessageAtom()
  const { user } = useUserAtom()
  const scrollableDiv = useRef<HTMLDivElement>(null)
  const firstVisibleElementIdRef = useRef<string>()

  const loadMoreData = async (currentConversation: IAReceivedConversation) => {
    if (loading) {
      return
    }
    const conversation = {
      conversationType: currentConversation.conversationType,
      targetId: currentConversation.targetId
    }

    try {
      setLoading(true)
      // 记录当前的滚动高度
      if (messageData?.list[0].messageUId) {
        firstVisibleElementIdRef.current = messageData?.list[0].messageUId
      }

      const resp = await getHistoryMessages(conversation, {
        timestamp: messageData?.list[0].sentTime || +new Date(),
        count: 20
      })
      if (resp.success) {
        if (resp.data?.list && messageData?.list) {
          resp.data.list = [...resp.data.list, ...messageData.list]
        }
        setMessageData(resp.data)

        // 使用 Promise 或 setTimeout 延迟滚动操作
        setTimeout(() => {
          if (firstVisibleElementIdRef.current && scrollableDiv.current) {
            // 计算元素的距离顶部的距离
            const elementTop = document.getElementById(
              firstVisibleElementIdRef.current
            )?.offsetTop

            // 将元素滚动到顶部的位置
            const maxScrollTop =
              scrollableDiv.current.scrollHeight - Number(elementTop)

            // 因为是column-reverse，所以maxScrollTop此时是在底部，需要再加上容器高度
            scrollableDiv.current.scrollTo({
              top: -maxScrollTop + scrollableDiv.current.clientHeight
            })
          }
        }, 0)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetGroupMembers = async (group_id: string) => {
    if (!group_id) {
      return
    }
    const resp = await getGroupMembers({ group_id })

    if (isSuccess(resp)) {
      setMembers(resp.data.users)
    }
  }

  useEffect(() => {
    if (currentConversation?.conversationType === ConversationType.GROUP) {
      handleGetGroupMembers(currentConversation?.targetId || '')
    }
  }, [currentConversation?.targetId])

  const membersMap = useMemo(() => {
    const map = new Map<string, IGroupMemberItem>()

    members.forEach((item) => {
      map.set(item.user_id, item)
    })

    return map
  }, [members])

  const getShareMessage = (
    config: IConfig,
    key: number,
    data: IAReceivedMessage
  ) => {
    if (config.messageType === MessageEnum.Discuss) {
      return (
        <MessageItem
          extra={data.content.user.extra}
          type={MessageEnum.Discuss}
          self={data.senderUserId === user.id}
          key={key}
          config={config}
        />
      )
    }

    if (config.messageType === MessageEnum.Story) {
      return (
        <MessageItem
          extra={data.content.user.extra}
          type={MessageEnum.Story}
          self={data.senderUserId === user.id}
          key={key}
        />
      )
    }
    if (config.messageType === MessageEnum.Video) {
      return (
        <MessageItem
          extra={data.content.user.extra}
          type={MessageEnum.Video}
          self={data.senderUserId === user.id}
          key={key}
        />
      )
    }
    if (config.messageType === MessageEnum.Books) {
      return (
        <MessageItem
          extra={data.content.user.extra}
          type={MessageEnum.Books}
          self={data.senderUserId === user.id}
          key={key}
        />
      )
    }
  }

  const getMessageItem = (data: IAReceivedMessage, key: number) => {
    const groupType =
      currentConversation?.conversationType === ConversationType.GROUP &&
      data.senderUserId !== user.id

    const userData =
      groupType && data.senderUserId !== user.id
        ? { nickname: membersMap.get(data.senderUserId)?.nickname }
        : {}

    if (data.messageType === ERongCloudMessageType.TxtMsg) {
      const config = data.content?.user?.extra
      if (config) {
        return getShareMessage(config, key, data)
      }
      return (
        <MessageItem
          key={key}
          type={'text'}
          self={data.senderUserId === user.id}
          data={data}
          userData={userData}
        />
      )
    }

    if (data.messageType === ERongCloudMessageType.ImgMsg) {
      return (
        <MessageItem
          key={key}
          type={'image'}
          self={data.senderUserId === user.id}
          data={data}
          userData={userData}
        />
      )
    }

    return null
  }

  return (
    <div
      id="scrollableDiv"
      style={{
        flex: 1,
        overflowY: loading ? 'hidden' : 'auto',
        display: 'flex',
        flexDirection: 'column-reverse'
      }}
      ref={scrollableDiv}
    >
      <InfiniteScroll
        dataLength={messageData?.list.length || 0}
        next={() => {
          if (currentConversation) {
            loadMoreData(currentConversation)
          }
        }}
        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top. https://github.com/ankeetmaini/react-infinite-scroll-component/issues/281
        inverse={true}
        hasMore={messageData?.hasMore || false}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={messageData?.list}
          renderItem={(item, index) => getMessageItem(item, index)}
        />
      </InfiniteScroll>
    </div>

    // <div className="ChatPanelContainer" ref={chatPanelRef}>
    //   {messageData?.list.map((item, index) => getMessageItem(item, index))}
    //   {/* <MessageItem type={'text'} self={false} gotoSign={true} />
    // <MessageTime time={'14:00'} />
    // <MessageItem type={'text'} self={true} />
    // <MessageItem type={'text'} self={false} checkIt={true} />
    // <MessageItem type={'image'} self={false} />
    // <MessageItem type={'discuss'} self={false} />
    // <MessageItem type={'story'} self={false} />
    // <MessageItem type={'video'} self={false} />
    // <MessageItem type={'books'} self={false} />
    // <MessageItem type={'reply'} self={false} /> */}
    // </div>
  )
}

ChatPanelContainer.displayName = 'ChatPanelContainer'
