import { isSuccess, post } from '@/httpRequest'

export const userLogout = async () => {
  const response = await post<null>('/user/logout')

  if (isSuccess(response)) {
    return true
  } else {
    console.error(response.message)
    return false
  }
}
