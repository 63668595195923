import { Personal } from '@/components/Personal'
import { IStoryListItem } from '@/httpRequest/modules/novel/list'
import Icon from '@img/components/waterfall/story.png'
import personal from '@img/header/personal.png'
import draft from '@img/personal/drafts2.png'

import styles from '../waterfall.module.less'

interface Props {
  info?: IStoryListItem
  actionEl?: JSX.Element | null
  handleRead?: () => void
}

export function Books({ actionEl, info, handleRead }: Props) {
  return (
    // 置顶样式 styles.Pinned
    <div
      className={`${styles.waterfallBooksCard} waterfallBooksCard ${
        info?.is_author_pinned || info?.is_pinned ? styles.Pinned : ''
      }`}
    >
      <div className="hover" style={{ height: '100%' }}>
        {/* 作者信息 */}
        <Personal
          size={'small'}
          nickname={info?.author_name}
          avatar={info?.author_avatar || personal}
          userData={info}
        />

        {/* 列表 */}
        <ul>
          {info?.rec_data?.slice?.(0, 5).map?.((item, index) => {
            return (
              <li key={item.rec_resource_id}>
                <div className="index">{index + 1}</div>
                <div style={{ width: 195 }}>
                  <h3 className="sle">{item.rec_resource_name}</h3>
                  <p>{item.resource_author_name}</p>
                </div>
              </li>
            )
          })}
        </ul>

        {/* 图标 */}
        <img src={Icon} alt="icon" className="icon" width="45" />

        {/* 操作按钮 */}
        {actionEl}

        {/* 详情介绍 */}
        {!actionEl && (
          <div className="intro-detail" onClick={handleRead}>
            <h3 className="title sle">{info?.name}</h3>
            <div className="mle break-word-in-line">{info?.intro}</div>
          </div>
        )}
      </div>

      <div className="tags">
        {/* 草稿 */}
        {info?.status === 1 ? (
          <a className="draft">
            <button>
              <img src={draft} />
              DRAFT
            </button>
            <span>DRAFT (only visible to you)</span>
          </a>
        ) : null}
      </div>
    </div>
  )
}
