import { useEffect, useMemo } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { IAReceivedConversation } from '@rongcloud/imlib-next'
import { useAtomValue } from 'jotai'

import { Personal } from '@/components/Personal'
import { isSuccess } from '@/httpRequest'
import { getUserFriends } from '@/httpRequest/modules/imInfo/friends'
import { useMessageAtom } from '@/store/message'
import { loginStateAtom } from '@/store/user'
import personal from '@img/header/personal.png'

import { letters } from '../goup/CreateGroupStep1'
import styles from '../message.module.less'

export const Contacts = () => {
  const isLogin = useAtomValue(loginStateAtom)
  const { currentConversation, setConversation, userFriends, setUserFriends } =
    useMessageAtom()

  const userList = useMemo(() => {
    const sortData = userFriends.sort(
      (a, b) =>
        a.follower?.nickname.localeCompare(b.follower?.nickname || '') || 0
    )

    letters.forEach((preFix) => {
      const index = sortData.findIndex(
        (data) => data.follower?.nickname.toLocaleUpperCase()[0] === preFix
      )
      if (index !== -1) {
        sortData[index].preFix = preFix
      }
    })

    return sortData
  }, [userFriends])

  const openChat = (id: number | string) => {
    setConversation({
      targetId: String(id),
      conversationType: RongIMLib.ConversationType.PRIVATE
    } as unknown as IAReceivedConversation)
  }

  const handleGetUserFriends = async () => {
    if (!isLogin) {
      return
    }

    const resp = await getUserFriends({ page: 1, size: 1000 })

    if (resp && isSuccess(resp)) {
      setUserFriends(resp.data.list)
    }
  }

  useEffect(() => {
    handleGetUserFriends()
  }, [])

  return (
    <div
      className={`${styles.ContactList} ${styles.ContactListRead}`}
      style={{ flex: 1, overflowY: 'scroll' }}
    >
      <ul>
        {userList.map((item) => (
          <>
            {item.preFix && <div className="letter">{item.preFix}</div>}
            <li
              key={item.follower_id}
              className={`${
                currentConversation?.targetId === item.follower?.user_code
                  ? 'active'
                  : ''
              } notread`}
              onClick={() => openChat(item.follower?.user_code || '')}
            >
              <Personal
                nickname={item.follower?.nickname}
                avatar={item.follower?.avatar || personal}
                userData={{}}
              />
            </li>
          </>
        ))}
      </ul>
    </div>
  )
}
