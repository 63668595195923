import React, { useMemo, useState } from 'react'

import { Checkbox, message } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import { SearchInput } from '@/components/Message/components/SearchInput'
import NoData from '@/components/NoData'
import { Personal } from '@/components/Personal'
import { isSuccess } from '@/httpRequest'
import { postGroupAdd } from '@/httpRequest/modules/imInfo/groupAdd'
import { getGroupMembers } from '@/httpRequest/modules/imInfo/groupMembers'
import { useMessageAtom } from '@/store/message'
import DelSrc from '@img/components/del2.png'

import styles from './index.module.less'

interface IProps {}

export const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

const AddGroupMembersPane: React.FC<IProps> = () => {
  const {
    setShowAddGroupMembers,
    userFriends,
    currentConversation,
    setMembers,
    members
  } = useMessageAtom()
  const [userIds, setUserIds] = useState<number[]>([])
  const [searchVal, setSearchVal] = useState('')

  const userList = useMemo(() => {
    const sortData = userFriends
      .filter(
        (item) => !['system', 'assistant'].includes(String(item.follower?.id))
      )
      .filter(
        (item) =>
          !members.some((mem) => mem.user_id === item.follower?.user_code)
      )
      .sort(
        (a, b) =>
          a.follower?.nickname.localeCompare(b.follower?.nickname || '') || 0
      )

    letters.forEach((preFix) => {
      const index = sortData.findIndex(
        (data) => data.follower?.nickname.toLocaleUpperCase()[0] === preFix
      )
      if (index !== -1) {
        sortData[index].preFix = preFix
      }
    })

    return sortData
  }, [userFriends, members])

  const handleSelect = (id: number) => {
    if (userIds.includes(id)) {
      setUserIds(userIds.filter((item) => item !== id))
    } else {
      setUserIds([...userIds, id])
    }
  }

  const handleGetGroupMembers = async (group_id: string) => {
    const resp = await getGroupMembers({ group_id })

    if (isSuccess(resp)) {
      setMembers(resp.data.users)
      setShowAddGroupMembers(false)
    }
  }

  const handleConfirm = async () => {
    const resp = await postGroupAdd({
      group_id: currentConversation?.targetId || '',
      user_ids: userIds.join(',')
    })

    if (isSuccess(resp)) {
      handleGetGroupMembers(currentConversation?.targetId || '')
      message.success('success')
    }
  }

  return (
    <>
      <div className="step1-left">
        <div className="search-box">
          <SearchInput
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
          />
        </div>

        {userList.some(
          (item) => item.follower?.nickname.indexOf(searchVal) !== -1
        ) ? (
          <div className={`${styles.ContactList} scroll-box`}>
            <ul>
              {userList.map((item) => {
                return (
                  <div
                    key={item.follower_id}
                    className={`${
                      item.follower?.nickname.indexOf(searchVal) !== -1
                        ? ''
                        : 'g-hidden'
                    }`}
                    onClick={() => {
                      handleSelect(item.follower_id as number)
                    }}
                  >
                    {item.preFix && <div className="letter">{item.preFix}</div>}
                    <li>
                      <Checkbox
                        className="ml4 mr10 c-radio"
                        value={item.follower_id}
                        checked={userIds.includes(item.follower_id || NaN)}
                      />
                      <Personal
                        nickname={item.follower?.nickname}
                        avatar={item.follower?.avatar}
                        userData={{}}
                      />
                    </li>
                  </div>
                )
              })}
            </ul>
          </div>
        ) : (
          <div style={{ marginTop: 200 }}>
            <NoData tip="​Sorry, we couldn't find any results for your search." />
          </div>
        )}
      </div>
      <div className="step1-right">
        <div className={`${styles.ContactList} scroll-box`}>
          <ul>
            {userList.map((item) => {
              return userIds.includes(item.follower_id || NaN) ? (
                <li key={item.follower_id}>
                  <Personal
                    nickname={item.follower?.nickname}
                    avatar={item.follower?.avatar}
                    userData={{}}
                  />
                  <a>
                    <img
                      src={DelSrc}
                      width={18}
                      onClick={() => {
                        setUserIds(
                          userIds.filter(
                            (userId) => userId !== item.follower_id
                          )
                        )
                      }}
                    />
                  </a>
                </li>
              ) : null
            })}
          </ul>
        </div>
        {/* 按钮 */}
        <div className="footer">
          <button
            className="g-btn mr20"
            onClick={() => setShowAddGroupMembers(false)}
          >
            Cancel
          </button>
          <button className="g-btn g-btn-primary mr20" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default AddGroupMembersPane
