import React, { useMemo, useState } from 'react'

import { Checkbox, message } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import { SearchInput } from '@/components/Message/components/SearchInput'
import NoData from '@/components/NoData'
import { Personal } from '@/components/Personal'
import { isSuccess } from '@/httpRequest'
import { delGroupExit } from '@/httpRequest/modules/imInfo/groupExit'
import {
  EMemberType,
  getGroupMembers
} from '@/httpRequest/modules/imInfo/groupMembers'
import { useMessageAtom } from '@/store/message'
import DelSrc from '@img/components/del2.png'

import styles from './index.module.less'

interface IProps {}

export const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

const DropGroupMembersPane: React.FC<IProps> = () => {
  const { setShowDropGroupMembers, members, setMembers, currentConversation } =
    useMessageAtom()
  const [userIds, setUserIds] = useState<string[]>([])
  const [searchVal, setSearchVal] = useState('')

  const userList = useMemo(() => {
    const sortData = members
      .filter((item) => item.user_role !== EMemberType.BACHELORS)
      .sort((a, b) => a?.nickname.localeCompare(b?.nickname || '') || 0)

    letters.forEach((preFix) => {
      const index = sortData.findIndex(
        (data) => data?.nickname.toLocaleUpperCase()[0] === preFix
      )
      if (index !== -1) {
        sortData[index].preFix = preFix
      }
    })

    return sortData
  }, [members])

  const handleSelect = (id: string) => {
    if (userIds.includes(id)) {
      setUserIds(userIds.filter((item) => item !== id))
    } else {
      setUserIds([...userIds, id])
    }
  }

  const handleGetGroupMembers = async (group_id: string) => {
    const resp = await getGroupMembers({ group_id })

    if (isSuccess(resp)) {
      setMembers(resp.data.users)
      setShowDropGroupMembers(false)
    }
  }

  const handleConfirm = async () => {
    const resp = await delGroupExit({
      group_id: currentConversation?.targetId || '',
      user_ids: userIds.join(',')
    })

    if (isSuccess(resp)) {
      handleGetGroupMembers(currentConversation?.targetId || '')
      message.success('success')
    }
  }

  return (
    <>
      <div className="step1-left">
        <div className="search-box">
          <SearchInput
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
          />
        </div>
        {userList.some((item) => item.nickname.indexOf(searchVal) !== -1) ? (
          <div className={`${styles.ContactList} scroll-box`}>
            <ul>
              {userList.map((item) => {
                return (
                  <div
                    key={item.user_id}
                    className={`${
                      item?.nickname.indexOf(searchVal) !== -1 ? '' : 'g-hidden'
                    }`}
                    onClick={() => {
                      handleSelect(item.user_id)
                    }}
                  >
                    {item.preFix && <div className="letter">{item.preFix}</div>}
                    <li>
                      <Checkbox
                        className="ml4 mr10 c-radio"
                        value={item.user_id}
                        checked={userIds.includes(item.user_id || '')}
                      />
                      <Personal
                        nickname={item?.nickname}
                        avatar={item?.avatar}
                        userData={{}}
                      />
                    </li>
                  </div>
                )
              })}
            </ul>
          </div>
        ) : (
          <div style={{ marginTop: 200 }}>
            <NoData tip="​Sorry, we couldn't find any results for your search." />
          </div>
        )}
      </div>
      <div className="step1-right">
        <div className={`${styles.ContactList} scroll-box`}>
          <ul>
            {userList.map((item) => {
              return userIds.includes(item.user_id) ? (
                <li key={item.user_id}>
                  <Personal
                    nickname={item?.nickname}
                    avatar={item?.avatar}
                    userData={{}}
                  />
                  <a>
                    <img
                      src={DelSrc}
                      width={18}
                      onClick={() => {
                        setUserIds(
                          userIds.filter((userId) => userId !== item.user_id)
                        )
                      }}
                    />
                  </a>
                </li>
              ) : null
            })}
          </ul>
        </div>
        {/* 按钮 */}
        <div className="footer">
          <button
            className="g-btn mr20"
            onClick={() => setShowDropGroupMembers(false)}
          >
            Cancel
          </button>
          <button className="g-btn g-btn-primary mr20" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default DropGroupMembersPane
