import { atom, useAtom } from 'jotai'

export const searchValAtom = atom('')
searchValAtom.debugLabel = 'searchVal'
export const useSearchValAtom = () => {
  const [searchVal, setSearchVal] = useAtom(searchValAtom)

  return {
    searchVal,
    setSearchVal
  }
}
