import { FC, useContext } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { Tooltip, Upload, UploadProps } from 'antd'

import { EmojiData } from '@/components/Message/EmojiData'
import { useMessageAtom } from '@/store/message'
import EmojiSrc from '@img/message/emoji.png'
import ImgSrc from '@img/message/img.png'
import RecordsSrc from '@img/message/records.png'

import { ChatPanelInputContext } from '../context'
import styles from '../message.module.less'
import { sendImgMessage, sendMessage } from '../utils'

export interface IToolBarProps {
  disabled?: boolean
}

export const ToolBar: FC<IToolBarProps> = (props) => {
  const { disabled } = props
  const { setChatRecords } = useMessageAtom()
  const { currentConversation, messageData, setMessageData, isInitialized } =
    useMessageAtom()
  const { textareaRef, inputChange } = useContext(ChatPanelInputContext)

  const handleSendImgMessage = async (file: File) => {
    if (isInitialized) {
      const conversation = {
        conversationType:
          currentConversation?.conversationType ||
          RongIMLib.ConversationType.PRIVATE,
        targetId: currentConversation?.targetId || ''
      }
      const msgBody = {
        file, // 待上传文件
        user: { id: '', name: '', portraitUri: '', extra: '' }, // 消息中携带的用户信息，非必填
        extra: '' // 消息中携带的透传信息，非必填
      }
      const resp = await sendImgMessage(conversation, msgBody)

      if (resp.success) {
        if (messageData?.list && resp.data) {
          messageData.list = [...messageData.list, resp.data]
          setMessageData({ ...messageData })
        }
      }
    }
  }

  const uploadOption: UploadProps = {
    name: 'file',
    accept: 'image/*',
    customRequest: (options) => {
      handleSendImgMessage(options.file as unknown as File)
    }
  }

  const handleSendEmoji = async (emoji: string) => {
    const textArea = textareaRef?.current?.resizableTextArea?.textArea
    if (textArea) {
      const startPos = textArea.selectionStart
      const endPos = textArea.selectionEnd
      const value = textArea.value

      const newValue = value.slice(0, startPos) + emoji + value.slice(endPos)
      inputChange(newValue)
      const newCursorPos = startPos + emoji.length
      textArea.selectionStart = newCursorPos
      textArea.selectionEnd = newCursorPos
      textArea.focus()
    }
  }
  return (
    <div className="ToolBar">
      {disabled ? (
        <a className="btn-action-face" />
      ) : (
        <Tooltip
          color={'#2F3132'}
          title={
            <div className={`${styles.emojiBox} emojiBox`}>
              <ul>
                {EmojiData.map((emoji, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleSendEmoji(emoji)
                    }}
                  >
                    {emoji}
                  </li>
                ))}
              </ul>
            </div>
          }
          placement="top"
          overlayClassName="emojiToolTip"
        >
          <a className="btn-action-face" />
        </Tooltip>
      )}

      {disabled ? (
        <a className="btn-action-pic" />
      ) : (
        <Upload {...uploadOption} showUploadList={false}>
          <a className="btn-action-pic" />
        </Upload>
      )}

      <a className="btn-action-box" onClick={() => setChatRecords(true)} />
    </div>
  )
}
