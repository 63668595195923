import { get } from '@/httpRequest'

export type TUserInfo = {
  /**
   * 头像链接
   */
  avatar_url: string
  /**
   * 余额
   */
  balance: number
  /**
   * 生日，前端来转对应的日期格式
   */
  birthday: string
  /**
   * 介绍
   */
  description: null | string
  /**
   * 邮箱
   */
  email: string
  /**
   * 粉丝数
   */
  followers: number
  /**
   * 关注数
   */
  following: number
  /**
   * 性别，0未知，1男，2女
   */
  gender: number
  /**
   * 用户id
   */
  id: string
  /**
   * 收入
   */
  income: number
  /**
   * 手机号
   */
  mobile: null | string
  /**
   * 昵称
   */
  nickname: null | string
  /**
   * 邀请人数
   */
  quantity: number
  /**
   * 邀请码
   */
  referral_code: string
  /**
   * 区域
   */
  region: null | string

  /** 作者id */
  author_id: string

  register_type: string

  created_at: string
}

export const getUserInfo = async () => {
  const response = await get<TUserInfo>('/user/detail/me')

  return response
}
