import { get } from '@/httpRequest'

export interface IFriendsRequest {
  page: number
  size: number
}

export interface IFriendsResponse {
  count: number
  list: IFriendItem[]
}

export interface IFriendItem {
  preFix?: string
  follower?: Follower
  /**
   * 好友用户id
   */
  follower_id?: number
  following_id?: number
}

export interface Follower {
  /**
   * 头像
   */
  avatar: string
  /**
   * 邮箱
   */
  email: string
  /**
   * 好友用户id
   */
  id: number
  /**
   * 名称
   */
  nickname: string
  /**
   * im用户id
   */
  user_code: string
}

export const getUserFriends = async (params: IFriendsRequest) => {
  const response = await get<IFriendsResponse>(`/im/user/friends`, params)

  return response
}
