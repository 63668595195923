import { isSuccess, post } from '@/httpRequest'
import { store } from '@/store'
import { loginStateAtom } from '@/store/user'

export type TRequest = {
  /**
   * 邮箱
   */
  email: string
  /**
   * 密码
   */
  password: string
}

export type TLogin = {
  /**
   * 过期时间
   */
  expire: string
  /**
   * 刷新token
   */
  refresh_token: string
  /**
   * jwt
   */
  token: string
}

export const login = async (params: TRequest) => {
  const response = await post<TLogin>('/user/login', params)

  if (isSuccess(response)) {
    const { token, refresh_token, expire: tokenExpire } = response.data

    // 设置 token 和刷新 token 到本地存储
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refresh_token)
    localStorage.setItem('tokenExpire', tokenExpire)
    store.set(loginStateAtom, true)

    // 登录成功后的其他逻辑
    // ...
  } else {
    console.error(response.message)
  }

  return response
}

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  const response = await post<TLogin>(
    '/user/refreshToken',
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    }
  )

  if (isSuccess(response)) {
    const { token, refresh_token, expire: tokenExpire } = response.data

    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refresh_token)
    localStorage.setItem('tokenExpire', tokenExpire)
  } else {
    console.error(response.message)
  }

  return response
}

export const checkTokenExpiration = () => {
  const token = localStorage.getItem('token')
  const tokenExpire = localStorage.getItem('tokenExpire')

  if (token && tokenExpire) {
    const tokenExpireDate = new Date(Number(tokenExpire) * 1000)
    const currentDate = new Date()

    // 计算距离过期还有多少毫秒
    const timeUntilExpiration =
      tokenExpireDate.getTime() - currentDate.getTime()

    // 判断是否距离过期不足10分钟
    if (
      60 * 1000 < timeUntilExpiration &&
      timeUntilExpiration < 10 * 60 * 1000
    ) {
      // 调用 refreshToken 函数刷新 token
      refreshToken()
    }
  }
}
