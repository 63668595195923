// Sentry initialization should be imported first!
import './instrument'
import './sensors_config'
import { ConfigProvider } from 'antd'
import { Provider } from 'jotai'
import { DevTools } from 'jotai-devtools'
import { createRoot } from 'react-dom/client'
import { useRoutes, BrowserRouter } from 'react-router-dom'

import Layout from '@/layouts/index'
import route from '@/router/config'

// eslint-disable-next-line import/order
import { GlobalLoading } from './components/GlobalLoading'
// eslint-disable-next-line import/order
import NoData from './components/NoData'
import useVerifyPaymentResult from './components/TopupModal/hooks/useVerifyPaymentResult'
// eslint-disable-next-line import/order
import { checkTokenExpiration } from './httpRequest/modules/user/login'

import 'antd/dist/reset.css'

import '@/assets/styles/reset.css'
import '@/assets/styles/components.less'

import ProtectedRoute from './router'
import { store } from './store'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

const Router = () => useRoutes(route)

// 在组件外部创建定时器
const timerId = setInterval(() => {
  checkTokenExpiration()
}, 10 * 1000)

// 在应用程序卸载时清除定时器
window.addEventListener('beforeunload', () => {
  clearInterval(timerId)
  console.log('定时器被清除了')
})

const App = () => {
  useVerifyPaymentResult()

  return (
    <ConfigProvider
      renderEmpty={() => (
        <NoData tip="Sorry, this section currently has no content." />
      )}
    >
      <Provider store={store}>
        <DevTools store={store} />
        <BrowserRouter>
          <Layout>
            <ProtectedRoute>
              <Router />
              <GlobalLoading />
            </ProtectedRoute>
          </Layout>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  )
}

root.render(<App />)
