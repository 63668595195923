// 消息类型
export enum MessageEnum {
  System = 'system',
  Discuss = 'discuss', // 讨论帖
  Story = 'story', // 故事
  Video = 'video', // 视频
  Books = 'books', // 书单
  Text = 'text', // 文本
  Image = 'image', // 图片
  Reply = 'reply' // 回复
}
