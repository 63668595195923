import {
  EContractStatus,
  EWorkStatus,
  IStoryListItem
} from '@/httpRequest/modules/novel/list'
import completed from '@img/components/waterfall/completed.png'
import hot from '@img/components/waterfall/hot.png'
import linked from '@img/components/waterfall/linked.png'
import pay from '@img/components/waterfall/pay.png'
import sign from '@img/components/waterfall/sign.png'
import imgSrc from '@img/home/img.png'
import draft from '@img/personal/drafts2.png'

import styles from '../waterfall.module.less'

export interface StoryItem {
  id?: string
  title: string
  author?: string
  cover?: string
  desc?: string
}
export interface StoryProp {
  title?: string
  author?: string
  cover?: string
  className?: string
  children?: JSX.Element | null
  onClick?: (e: React.KeyboardEvent | React.MouseEvent) => void
  actionEl?: JSX.Element | null
  info?: IStoryListItem
  handleRead?: () => void
}

export function SimpleStory({
  title,
  author,
  cover,
  className,
  children,
  onClick
}: StoryProp) {
  return (
    <div
      className={[styles.waterfallStoryCard, className || ''].join(' ')}
      onClick={onClick}
    >
      {/* 图片 */}
      <img src={cover || imgSrc} className="pic" />

      {/* 信息 */}
      <div className="infos">
        <div className="sle">{title}</div>
        <p>{author}</p>
      </div>

      {children && children}
    </div>
  )
}

export function Story({
  className,
  onClick,
  actionEl,
  info,
  handleRead
}: StoryProp) {
  return (
    // 置顶样式 styles.Pinned
    <div
      className={[
        styles.waterfallStoryCard,
        className || '',
        info?.is_author_pinned || info?.is_pinned ? styles.Pinned : ''
      ].join(' ')}
      onClick={onClick}
    >
      <div className="hover">
        {/* 图片 */}
        <img src={info?.cover_pic_url || imgSrc} className="pic" />

        {/* 信息 */}
        <div className="infos">
          <div className="sle">{info?.name}</div>
          <p>{info?.author_name}</p>
        </div>

        {/* 热门 */}
        {info?.is_hot ? <img src={hot} className="hot" /> : null}

        {/* 详情介绍 */}
        {!actionEl && (
          <div className="intro-detail" onClick={handleRead}>
            <div className="mle break-word-in-line">{info?.intro}</div>
          </div>
        )}

        {/* 操作按钮 */}
        {actionEl}
      </div>

      {/* 标签 */}
      <div className="tags">
        {/* 已签约 */}
        {info?.sign_status === EContractStatus.COMPLETED || info?.is_sign ? (
          <a>
            <img src={sign} />
            <span>Signed</span>
          </a>
        ) : null}
        {/* 已完结 */}
        {info?.is_finish ? (
          <a>
            <img src={completed} />
            <span>Completed</span>
          </a>
        ) : null}
        {/* 付费 */}
        {info?.is_pay ? (
          <a>
            <img src={pay} />
            <span>On Sale</span>
          </a>
        ) : null}
        {/* 链接 */}
        {info?.resource_type === 2 ||
        (info?.resource_type === 3 && info.is_ad) ? (
          <a>
            <img src={linked} />
            <span>External Linked</span>
          </a>
        ) : null}

        {/* 草稿 */}
        {info?.status === EWorkStatus.DRAFT ? (
          <a className="draft">
            <button>
              <img src={draft} />
              DRAFT
            </button>
            <span>DRAFT (only visible to you)</span>
          </a>
        ) : null}
      </div>
    </div>
  )
}
