import { useEffect, useState } from 'react'

import { useMessageAtom } from '@/store/message'

import { CreateGroupStep1 } from './CreateGroupStep1'
import { CreateGroupStep2 } from './CreateGroupStep2'
import styles from '../message.module.less'

export const CreateGroup = () => {
  const [step, setStep] = useState('step1')
  const [userIds, setUserIds] = useState<number[]>([])
  const { setPersonalGrouping } = useMessageAtom()

  const onChange = (val: string) => {
    setStep(val)
  }

  useEffect(() => {
    return () => {
      setPersonalGrouping(false)
    }
  }, [])

  return (
    <div className={styles.CreateGroup}>
      {/* 头部栏 */}
      <div className="header">
        <div className="f18 c-white Toppan">Create a new group</div>
      </div>

      {/* 内容区 */}
      <div className="container">
        {step === 'step1' && (
          <CreateGroupStep1
            change={onChange}
            userIds={userIds}
            setUserIds={setUserIds}
          />
        )}
        {step === 'step2' && (
          <CreateGroupStep2 change={onChange} userIds={userIds} />
        )}
      </div>
    </div>
  )
}
