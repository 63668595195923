import sensors from 'sa-sdk-javascript'

import { isSuccess } from '@/httpRequest'
import { getUserInfo } from '@/httpRequest/modules/user/userInfo'

export const handleLoginTrack = async () => {
  const resp = await getUserInfo()
  if (resp && isSuccess(resp)) {
    const userData = resp.data

    sensors.login(userData.id)

    sensors.track('user_login', {
      gender: userData.gender,
      register_type: userData.register_type,
      created_at: userData.created_at,
      author_id: userData.author_id
    })
  }
}

export const handleContentViewTrack = (chapterInfo: unknown) => {
  sensors.track('content_view', {
    ...(chapterInfo as object)
  })
}

export const handleShelfClickTrack = () => {
  sensors.track('shelf_click')
}

export const handleSearchInputTrack = () => {
  sensors.track('search_input')
}

export const handleSearchKeywordTrack = (
  keyword: string,
  result_count: number,
  search_type: string
) => {
  sensors.track('search_keyword', {
    keyword,
    result_count,
    search_type
  })
}

export const handleImGroupOpenTrack = (
  resource_type: string,
  resource_id: string,
  tag: string,
  im_group_code: string
) => {
  sensors.track('imgroup_open', {
    resource_type,
    resource_id,
    tag,
    im_group_code
  })
}

export const handleImClickTrack = (from: string, contact_uid: string) => {
  sensors.track('im_click', {
    from,
    contact_uid
  })
}

export const handleTopUpTrack = (
  channel: string,
  resource_type: string,
  resource_id: string
) => {
  sensors.track('top_up', {
    from: window.location.href,
    channel,
    resource_type,
    resource_id
  })
}

export const handlePublishClickTrack = () => {
  sensors.track('publish_click')
}

export const handleFeaturedClickTrack = (
  featured_position: number,
  resource_type: string,
  resource_id: string
) => {
  sensors.track('featured_click', {
    featured_position,
    resource_type,
    resource_id
  })
}

export const handleDetailViewTrack = (
  resource_type: string,
  resource_id: string,
  tag?: string
) => {
  sensors.track('detail_view', {
    resource_type,
    resource_id,
    tag
  })
}
