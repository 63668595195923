import { post } from '@/httpRequest'

export interface IIMGroupRequest {
  /**
   * 群头像
   */
  avatar: string
  /**
   * 群成员
   */
  member_ids: number[]
  /**
   * 群名
   */
  name: string
  /**
   * 内容资源id
   */
  resource_id?: string
  /**
   * 内容资源类型，1Novel,2Ad故事,3剧集,4书单
   */
  resource_type?: string
}

export type IIMGroupResponse = {
  content: string
  name: string
}

export const createIMGroup = async (params: IIMGroupRequest) => {
  const response = await post<IIMGroupResponse>(`/im/group`, params)

  return response
}
