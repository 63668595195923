import { useMessageAtom } from '@/store/message'

import DropGroupMembersPane from './DropGroupMembersPane'
import styles from './index.module.less'

const DropGroupMembers = () => {
  const { members } = useMessageAtom()

  return (
    <div className={styles.CreateGroup}>
      {/* 头部栏 */}
      <div className="header">
        <div className="f18 c-white Toppan">Remove from group</div>
        <div className="c-999">
          <span className="c-ffc585">{members.length}</span> Members
        </div>
      </div>

      {/* 内容区 */}
      <div className="container">
        <DropGroupMembersPane />
      </div>
    </div>
  )
}

export default DropGroupMembers
