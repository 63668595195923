import { message } from 'antd'

import { ApiResult } from '@/httpRequest'

export const getGlobalConfig = () => {
  const ENV = import.meta.env
  const {
    VITE_GLOB_API_URL,
    VITE_RONG_CLOUD_APP_KEY,
    VITE_RONG_CLOUD_AREA_CODE,
    VITE_PAYPAL_CLIENT_ID,
    VITE_AIRWALLEX_PAYMENT_ENV,
    VITE_ENVIRONMENT,
    VITE_SENSORS_TRACK_URL
  } = ENV

  return {
    VITE_GLOB_API_URL,
    VITE_RONG_CLOUD_APP_KEY,
    VITE_RONG_CLOUD_AREA_CODE,
    VITE_PAYPAL_CLIENT_ID,
    VITE_AIRWALLEX_PAYMENT_ENV,
    VITE_ENVIRONMENT,
    VITE_SENSORS_TRACK_URL
  }
}

export const handleUnlockResponse = (
  response: ApiResult<{ reason: number }>,
  success: () => void,
  showRechargeModal: () => void
) => {
  const { data } = response

  if (!data) {
    message.error('系统出现问题,请稍后重试')
    return
  }

  const { reason } = data

  switch (reason) {
    case -1:
      // 已经解锁,触发成功回调函数
      success()
      break
    case -2:
    case -3:
    case -5:
      // 资源类型不支持解锁 / 解锁资源不存在 / 系统bug
      message.error(response.message)
      break
    case -4:
      // 钱不够,弹出充值弹窗
      showRechargeModal()
      break
    default:
      message.error('未知错误')
  }
}

export const isClickInsideElement = (
  element: HTMLElement,
  event: React.MouseEvent<HTMLDivElement>
): boolean => {
  const rect = element.getBoundingClientRect()
  return (
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom
  )
}
