import { get } from '@/httpRequest'

export interface IUserInkResponse {
  /**
   * 余额
   */
  balance: number
  /**
   * 收入
   */
  income: number
}

export const getUserInk = async () => {
  const response = await get<IUserInkResponse>('/user/ink')

  return response
}
