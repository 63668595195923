import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import { useGlobalLoading } from '@/store/globalLoading'

export const GlobalLoading = () => {
  const { globalLoading } = useGlobalLoading()

  return (
    <Spin
      spinning={globalLoading}
      fullscreen
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    />
  )
}
