import { useMemo, useRef, useState } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { ConversationType } from '@rongcloud/imlib-next'
import { Input, message } from 'antd'
import type { TextAreaRef } from 'antd/es/input/TextArea'
import { replace } from 'lodash-es'

import { sendMessage } from '@/components/Message/utils'
import { useMessageAtom } from '@/store/message'
import SendActiveSrc from '@img/message/send-active.png'
import SendSrc from '@img/message/send.png'

import { ToolBar } from './ToolBar'
import { ChatPanelInputContext } from '../context'

const { TextArea } = Input

export const ChatPanelInput = () => {
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [value, setValue] = useState<string>()
  const {
    currentConversation,
    messageData,
    setMessageData,
    isInitialized,
    userFriends,
    userGroups
  } = useMessageAtom()
  const textareaRef = useRef<TextAreaRef>(null)

  const disableInput = useMemo(() => {
    if (currentConversation?.conversationType === ConversationType.PRIVATE) {
      return !userFriends.some(
        (item) => item.follower?.user_code === currentConversation?.targetId
      )
    }

    if (currentConversation?.conversationType === ConversationType.GROUP) {
      return !userGroups.some(
        (item) => item.group_code === currentConversation?.targetId
      )
    }
  }, [currentConversation, userFriends, userGroups])

  const inputChange = (value?: string) => {
    setValue(value)
    setBtnDisabled(value === '')
  }

  const handleSendMessage = async () => {
    if (!value) {
      return message.warning('Send content cannot be empty')
    }
    if (isInitialized && value) {
      // 定义消息投送目标会话
      const conversation = {
        conversationType:
          currentConversation?.conversationType ||
          RongIMLib.ConversationType.PRIVATE,
        targetId: currentConversation?.targetId || ''
      }
      const resp = await sendMessage(
        conversation,
        // eslint-disable-next-line no-control-regex
        replace(value, new RegExp('\n', 'g'), '<br>')
      )
      if (resp.success) {
        if (messageData?.list && resp.data) {
          messageData.list = [...messageData.list, resp.data]
          setMessageData({ ...messageData })
          setValue('')
        }
      }
    }
  }

  const ChatPanelInputContextVal = useMemo(() => {
    return {
      textareaRef,
      inputChange
    }
  }, [textareaRef])

  return (
    <ChatPanelInputContext.Provider value={ChatPanelInputContextVal}>
      <div className="ChatPanelInput">
        {/* 操作栏 */}
        <ToolBar disabled={disableInput} />

        {/* 输入框 */}
        <div className="sendBox">
          <TextArea
            ref={textareaRef}
            value={value}
            onChange={(e) => {
              inputChange(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                handleSendMessage()
              }
            }}
            placeholder="Text what you want..."
            autoSize={{ minRows: 5, maxRows: 5 }}
            className="send-textarea"
            disabled={disableInput}
          />
        </div>

        {/* 发送 */}
        <div className="tr">
          <button
            className={`g-btn ${
              btnDisabled || disableInput ? '' : 'g-btn-primary'
            }`}
            disabled={btnDisabled || disableInput}
            onClick={handleSendMessage}
          >
            <img src={btnDisabled ? SendSrc : SendActiveSrc} width={17} />
          </button>
        </div>
      </div>
    </ChatPanelInputContext.Provider>
  )
}
