import { message, Upload } from 'antd'
import type { UploadProps, UploadFile } from 'antd'
import type { RcFile, UploadChangeParam } from 'antd/es/upload/interface'

import { ApiResult, isSuccess } from '@/httpRequest'
import { TUploadResp } from '@/httpRequest/common/upload'
import { getGlobalConfig } from '@/utils'

import { EFileType } from './UploadImg'
import styles from '../index.module.less'

interface UploadAvatarProps {
  fileUrl?: string
  disabled?: boolean
  mode?: number
  className?: string
  onChange?: (url: string) => void
  type?: EFileType
  uploadButton?: (imgUrl: string) => React.ReactNode
}

const baseURL = getGlobalConfig().VITE_GLOB_API_URL

const beforeUpload = (file: RcFile) => {
  if (file.size > 2 * 1024 * 1024) {
    message.error('Please select an image smaller than 2M')
    return Upload.LIST_IGNORE
  }

  return true
}

const UploadAvatar = ({
  disabled,
  className = '',
  mode = 1,
  onChange,
  type = EFileType.Other,
  uploadButton,
  fileUrl
}: UploadAvatarProps) => {
  // 获取token
  const token = localStorage.getItem('token')
  const props: UploadProps = {
    name: 'file',
    action: `${baseURL}/common/upload`,
    accept: 'image/*',
    headers: {
      Authorization: `Bearer ${token}`
    },
    beforeUpload,
    data: {
      type
    },
    onChange: async (
      info: UploadChangeParam<UploadFile<ApiResult<TUploadResp>>>
    ) => {
      const resp = info.file.response
      if (resp && isSuccess(resp)) {
        typeof onChange === 'function' && onChange(resp.data.url)
      }
    }
  }

  return (
    <div
      className={[
        'flx-align-center mb20 ',
        styles.uploadFile,
        mode === 2 ? styles.uploadFileHorizontal : '',
        className
      ]
        .join(' ')
        .trim()}
    >
      <div>
        {!disabled && (
          <Upload {...props} showUploadList={false}>
            {uploadButton ? (
              uploadButton(fileUrl || '')
            ) : (
              <button className="g-btn g-btn-primary">Upload</button>
            )}
          </Upload>
        )}
      </div>
    </div>
  )
}
export default UploadAvatar
