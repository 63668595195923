import { FC, useEffect, useRef } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { IAReceivedConversation } from '@rongcloud/imlib-next'

import { getHistoryMessages } from '@/components/Message/utils'
import { store } from '@/store'
import {
  conversationListAtom,
  totalUnreadCountAtom,
  useMessageAtom
} from '@/store/message'
import { isClickInsideElement } from '@/utils'

import AddGroupMembers from './AddGroupMembers'
import { ChatPanelContainer } from './ChatPanelContainer'
import { ChatPanelHeader } from './ChatPanelHeader'
import { ChatPanelInput } from './ChatPanelInput'
import { ChatRecords } from './ChatRecords'
import DropGroupMembers from './DropGroupMembers'
import { GroupManage } from './GroupManage'
import styles from '../message.module.less'

export const Chatbox = () => {
  const {
    groupManageVisible,
    chatRecordsVisible,
    setGroupManage,
    currentConversation,
    showAddGroupMembers,
    showDropGroupMembers
  } = useMessageAtom()

  const { setMessageData, isInitialized } = useMessageAtom()

  const onClickPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = document.getElementById('groupManageEle')
    if (targetElement && isClickInsideElement(targetElement, event)) {
      // console.log('点击发生在目标元素内部')
      return
    } else if (groupManageVisible) {
      setGroupManage(false)
    }
  }

  const handleGetHistoryMessages = async (
    currentConversation: IAReceivedConversation
  ) => {
    const conversation = {
      conversationType: currentConversation.conversationType,
      targetId: currentConversation.targetId
    }

    const resp = await getHistoryMessages(conversation)
    console.log('获取会话历史消息', resp)
    if (resp.success) {
      setMessageData(resp.data)

      // 清除未读消息
      RongIMLib.clearMessagesUnreadStatus({
        conversationType: currentConversation.conversationType,
        targetId: currentConversation.targetId
      }).then((res) => {
        if (res.code === 0) {
          // 清理成功
          console.log('清除未读消息成功', res)
          const totalUnreadCountAtomData = store.get(totalUnreadCountAtom)
          store.set(
            totalUnreadCountAtom,
            totalUnreadCountAtomData -
              Number(currentConversation?.unreadMessageCount)
          )
          // 重置会话列表未读数
          const conversationListAtomData = store.get(conversationListAtom)
          if (conversationListAtomData) {
            const newList = conversationListAtomData.map((item) => {
              if (item.targetId === currentConversation.targetId) {
                item.unreadMessageCount = 0
              }
              return { ...item }
            })
            store.set(conversationListAtom, newList)
          }
          // 发送多端同步未读数消息
          RongIMLib.sendSyncReadStatusMessage(
            {
              conversationType: currentConversation.conversationType,
              targetId: currentConversation.targetId
            },
            Date.now()
          ).then(() => {})
        }
      })
    }
  }

  useEffect(() => {
    if (isInitialized && currentConversation) {
      handleGetHistoryMessages(currentConversation)
    }
  }, [isInitialized, currentConversation])

  return (
    <div className={styles.ChatboxModal}>
      <div className="chats-panel" onClick={onClickPanel}>
        {/* 头部栏 */}
        <ChatPanelHeader />

        {/* 消息框 */}
        <ChatPanelContainer />

        {/* 输入框 */}
        <ChatPanelInput />

        {/* 群管理 */}
        {groupManageVisible ? <GroupManage /> : null}
      </div>
      {/* 聊天记录 */}
      {chatRecordsVisible && <ChatRecords />}

      {showAddGroupMembers ? <AddGroupMembers /> : null}

      {showDropGroupMembers ? <DropGroupMembers /> : null}
    </div>
  )
}
