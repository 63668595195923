import { useEffect, useMemo, useState } from 'react'

import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ConversationType } from '@rongcloud/imlib-next'
import { Button, Input, Modal, message, type MenuProps } from 'antd'

import { SearchInput } from '@/components/Message/components/SearchInput'
import { Personal } from '@/components/Personal'
import { isSuccess } from '@/httpRequest'
import { delGroupDismiss } from '@/httpRequest/modules/imInfo/groupDismiss'
import { postGroupExitBySelf } from '@/httpRequest/modules/imInfo/groupExitBySelf'
import { getGroupList } from '@/httpRequest/modules/imInfo/groupList'
import {
  EMemberType,
  IGroupMemberItem,
  getGroupMembers
} from '@/httpRequest/modules/imInfo/groupMembers'
import { postGroupModifyGroupAvatar } from '@/httpRequest/modules/imInfo/groupModifyGroupAvatar'
import { postGroupModifyGroupName } from '@/httpRequest/modules/imInfo/groupModifyGroupName'
import UploadAvatar from '@/pages/Publish/components/UploadAvatar'
import { EFileType } from '@/pages/Publish/components/UploadImg'
import { useMessageAtom } from '@/store/message'
import { useUserAtom } from '@/store/user'
import MinusSrc from '@img/components/minus.png'
import MoreSrc from '@img/components/more2.png'
import PlusSrc from '@img/components/plus.png'

import styles from '../message.module.less'
import {
  clearHistoryMessages,
  getConversationList,
  removeConversation
} from '../utils'

export const GroupManage = () => {
  const {
    groupManageVisible,
    currentConversation,
    userGroups,
    members,
    setMembers,
    setShowAddGroupMembers,
    setShowDropGroupMembers,
    setUserGroups,
    setConversation,
    setTotalUnreadCount,
    setConversationList,
    setGroupManage
  } = useMessageAtom()
  const { user } = useUserAtom()
  const [showMembers, setShowMembers] = useState<IGroupMemberItem[]>([])
  const [searchVal, setSearchVal] = useState('')
  const [showMore, setShowMore] = useState(false)

  const [disableEdit, setDisableEdit] = useState(true)
  const currentGroupInfo = useMemo(() => {
    return userGroups.find(
      (item) => item.group_code === currentConversation?.targetId
    )
  }, [userGroups, currentConversation])
  const [groupName, setGroupName] = useState(currentGroupInfo?.name)
  const currentMemberInfo = useMemo(() => {
    return members.find((item) => item.user_id === String(user.id))
  }, [members, user, currentConversation])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a>View Profile</a>
    },
    {
      key: '2',
      label: <a>Configure Admin</a>
    },
    {
      key: '3',
      label: <a>Mute</a>
    },
    {
      key: '4',
      label: <a>Report</a>
    }
  ]

  const handleGetGroupMembers = async (group_id: string) => {
    if (!group_id) {
      return
    }
    const resp = await getGroupMembers({ group_id })

    if (isSuccess(resp)) {
      setMembers(resp.data.users)
      setShowMembers(resp.data.users)
    }
  }

  const handleRenderListItem = (item: IGroupMemberItem) => {
    return (
      <li>
        {/* <Dropdown menu={{ items }} placement="bottom" arrow>
          <div>
            <Personal
              userData={{}}
              nickname={personalInfo?.follower?.nickname}
              avatar={personalInfo?.follower?.avatar}
            />
          </div>
        </Dropdown> */}
        <div>
          <Personal
            userData={{ id: item.user_id }}
            nickname={item?.nickname}
            avatar={item?.avatar}
          />
        </div>
      </li>
    )
  }

  const handleSearchUser = (nickname: string) => {
    const result = members.filter(
      (item) => String(item.nickname).includes(nickname) || !nickname
    )
    setShowMembers(result)
  }

  const handleChangeGroupAvatar = async (avatar: string) => {
    const resp = await postGroupModifyGroupAvatar({
      avatar,
      group_id: currentConversation?.targetId || ''
    })

    if (isSuccess(resp)) {
      const result = userGroups.map((item) => {
        if (item.group_code === currentConversation?.targetId) {
          item.avatar = avatar
          return item
        }
        return item
      })

      setUserGroups(result)
      message.success('success')
    }
  }

  const handleChangeGroupName = async (name: string) => {
    const resp = await postGroupModifyGroupName({
      name,
      group_id: currentConversation?.targetId || ''
    })

    if (isSuccess(resp)) {
      const result = userGroups.map((item) => {
        if (item.group_code === currentConversation?.targetId) {
          item.name = name
          return item
        }
        return item
      })

      setUserGroups(result)
      setDisableEdit(true)
      message.success('success')
    }
  }

  const handleGetConversationList = async () => {
    const resp = await getConversationList()
    console.log(resp, '会话列表')
    if (resp.success) {
      const count = resp.data?.reduce((previousValue, currentValue) => {
        if (currentValue.unreadMessageCount) {
          return previousValue + currentValue?.unreadMessageCount
        }
        return previousValue
      }, 0)
      console.log('所有会话列表中的未读数', count)
      setGroupManage(false)
      setTotalUnreadCount(count || 0)
      setConversationList(resp.data)
    }
  }

  const handleGetGroupList = async () => {
    const resp = await getGroupList({})

    if (isSuccess(resp)) {
      setUserGroups(resp.data.list)
    }
  }

  const handleClearIMRelationship = async (following_id: string) => {
    const clearResult1 = await clearHistoryMessages(
      following_id,
      ConversationType.GROUP
    )
    console.log(`清除消息结果：${following_id}`, clearResult1)

    const clearResult2 = await removeConversation(
      following_id,
      ConversationType.GROUP
    )
    console.log(`清除会话结果：${following_id}`, clearResult2)

    handleGetGroupList()
    handleGetConversationList()
  }

  const handleExit = async () => {
    const resp = await postGroupExitBySelf({
      group_id: currentConversation?.targetId as string
    })

    if (isSuccess(resp)) {
      handleClearIMRelationship(currentConversation?.targetId as string)
      setConversation(undefined)
    }
  }

  const handleDisbandTheGroup = async () => {
    const resp = await delGroupDismiss({
      group_id: currentConversation?.targetId || ''
    })

    if (isSuccess(resp)) {
      handleClearIMRelationship(currentConversation?.targetId as string)
      setConversation(undefined)
      message.success('success')
    }
  }

  useEffect(() => {
    if (groupManageVisible) {
      setShowMembers(members)
    }
  }, [members, groupManageVisible])

  useEffect(() => {
    handleSearchUser('')
  }, [members])

  return (
    <div
      id="groupManageEle"
      className={`${styles.GroupManage} ${
        groupManageVisible ? styles.GroupManageShow : ''
      }`}
    >
      <div className="search-line">
        <SearchInput
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value)
            handleSearchUser(e.target.value)
          }}
        />
      </div>

      <div className="member-list">
        <ul className={styles.columnAuthorList}>
          {showMembers.slice(0, 6).map((item) => handleRenderListItem(item))}
          {showMore
            ? showMembers.slice(6).map((item) => handleRenderListItem(item))
            : null}
          <li
            onClick={() => {
              setShowAddGroupMembers(true)
            }}
          >
            <button className="g-btn g-btn-ghost">
              <img src={PlusSrc} width={18} />
            </button>
            <div className="f12 c-b3 tc mt2">Add</div>
          </li>
          {currentMemberInfo?.user_role === EMemberType.BACHELORS ? (
            <li
              onClick={() => {
                setShowDropGroupMembers(true)
              }}
            >
              <button className="g-btn g-btn-ghost">
                <img src={MinusSrc} width={18} />
              </button>
              <div className="f12 c-b3 tc mt2">Remove</div>
            </li>
          ) : null}
        </ul>

        {members.length > 6 ? (
          <div
            className="tc"
            onClick={() => {
              setShowMore(!showMore)
            }}
          >
            <a>
              <img src={MoreSrc} width={42} />
            </a>
          </div>
        ) : null}
      </div>

      <div className="mt28 ml20 mr20 mb20">
        <div className="f16 c-white mb14 Toppan">Group&apos;s cover</div>
        <div className="cover mb28">
          <img src={currentGroupInfo?.avatar} />
          {currentMemberInfo?.user_role === EMemberType.BACHELORS ? (
            <UploadAvatar
              type={EFileType.Avatar}
              onChange={(url) => {
                handleChangeGroupAvatar(url)
              }}
            />
          ) : null}
        </div>

        <div className="f16 c-white mb10 Toppan">Group Name</div>
        <div className="c-b3 mb24">
          <Input
            className={styles['group-input']}
            suffix={
              currentMemberInfo?.user_role === EMemberType.BACHELORS ? (
                <EditOutlined
                  onClick={() => {
                    setDisableEdit(false)
                  }}
                />
              ) : null
            }
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value)
            }}
            onBlur={() => {
              handleChangeGroupName(groupName || '')
            }}
            disabled={disableEdit}
          />
        </div>
        <div className="f16 c-white mt150 Toppan center">
          {[EMemberType.ADMIN, EMemberType.MEMBERS].includes(
            currentMemberInfo?.user_role as EMemberType
          ) ? (
            <Button
              className="g-btn g-btn-primary"
              onClick={() => {
                Modal.confirm({
                  title: 'Tip',
                  content: `You're leaving this group. `,
                  onOk: handleExit
                })
              }}
            >
              Leave Group
            </Button>
          ) : null}

          {[EMemberType.BACHELORS].includes(
            currentMemberInfo?.user_role as EMemberType
          ) ? (
            <Button
              className="g-btn g-btn-primary"
              onClick={() => {
                Modal.confirm({
                  title: 'Tip',
                  content: `You're deleting this group. `,
                  onOk: handleDisbandTheGroup
                })
              }}
            >
              Delete Group
            </Button>
          ) : null}
        </div>

        {/* <div className="f16 c-white mb10 Toppan">Announcement</div>
        <div className="c-b3 mb24">
          Gran trabajo,yun concepto gráfico muy interesante. ¡Felicidades por el
          resultado!
        </div> */}
      </div>
    </div>
  )
}
