import { get } from '@/httpRequest'

export interface IIMTokenResponse {
  /**
   * 头像
   */
  avatar_url: null | string
  im_token: string
  /**
   * 用户名称
   */
  name: string
  /**
   * 用户id，不用自增id，使用转换后的code，对应用户详细信息接口的referral_code
   */
  user_id: string
}

export const getIMToken = async () => {
  const response = await get<IIMTokenResponse>(`/im/user/imToken`)

  return response
}
