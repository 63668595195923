import { useState } from 'react'

import { SearchInput } from '@/components/Message/components/SearchInput'
import { useMessageAtom } from '@/store/message'
import { isClickInsideElement } from '@/utils'
import DelSrc from '@img/components/close.png'
import Plus from '@img/message/plus.png'

import { Contacts } from './Contacts'
import { Groups } from './Groups'
import { Messages } from './Messages'
import styles from '../message.module.less'

enum ContactEnum {
  Message = 'message',
  Group = 'group',
  Contact = 'contact'
}

export const ContactsModal = () => {
  const {
    toggleMessageModalVisible,
    messageModalVisible,
    setCreateCroup,
    setPersonalGrouping,
    setGroupManage,
    groupManageVisible
  } = useMessageAtom()

  const [tab, setTab] = useState<ContactEnum>(ContactEnum.Message)

  const onClickPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = document.getElementById('groupManageEle')
    if (targetElement && isClickInsideElement(targetElement, event)) {
      console.log('点击发生在目标元素内部')
      return
    } else if (groupManageVisible) {
      setGroupManage(false)
    }
  }

  return (
    <>
      <div
        className={`${styles.contactsModal} ${
          messageModalVisible ? styles.contactsModalShow : ''
        }`}
        onClick={onClickPanel}
      >
        {/* 关闭弹窗 */}
        <a className="btn-del" onClick={() => toggleMessageModalVisible(false)}>
          <img src={DelSrc} width={46} />
        </a>

        {/* 搜索&添加 */}
        <div className="top-actions flx-justify-between">
          <SearchInput />
          {tab === ContactEnum.Group && (
            <button
              className="g-btn g-btn-ghost"
              onClick={() => {
                setCreateCroup(true)
                setPersonalGrouping(true)
              }}
            >
              <img src={Plus} width={13} />
            </button>
          )}
        </div>

        {/* tab */}
        <ul className="tabs">
          <li
            className={
              tab === ContactEnum.Message ? 'message active' : 'message'
            }
            onClick={() => setTab(ContactEnum.Message)}
          >
            <i />
          </li>
          <li
            className={tab === ContactEnum.Group ? 'group active' : 'group'}
            onClick={() => setTab(ContactEnum.Group)}
          >
            <i />
          </li>
          <li
            className={
              tab === ContactEnum.Contact ? 'contacts active' : 'contacts'
            }
            onClick={() => setTab(ContactEnum.Contact)}
          >
            <i />
          </li>
        </ul>

        {/* 聊天 */}
        {tab === ContactEnum.Message && <Messages />}

        {/* 群组 */}
        {tab === ContactEnum.Group && <Groups />}

        {/* 通讯录 */}
        {tab === ContactEnum.Contact && <Contacts />}
      </div>
    </>
  )
}
