import { post } from '@/httpRequest'

export interface IGroupExitBySelfRequest {
  group_id: string
}

export const postGroupExitBySelf = async (params: IGroupExitBySelfRequest) => {
  const response = await post<null>(`/im/group/exitBySelf`, params)

  return response
}
