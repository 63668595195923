import {
  GetHistoryMessageResult,
  IAReceivedConversation
} from '@rongcloud/imlib-next'
import { atom, useAtom } from 'jotai'

import { IFriendItem } from '@/httpRequest/modules/imInfo/friends'
import { IGroupListItem } from '@/httpRequest/modules/imInfo/groupList'
import { IGroupMemberItem } from '@/httpRequest/modules/imInfo/groupMembers'

export interface messageState {
  showMessageModal: boolean // 消息列表弹窗
  currentConversation: IAReceivedConversation // 当前聊天窗会话
  showChatModal: boolean // 聊天窗
  showCreateCroup: boolean // 创建群聊
  showGroupManage: boolean // 群管理弹窗
  showChatRecords: boolean // 聊天记录
}

const showMessageModal = atom(false)
showMessageModal.debugLabel = 'showMessageModal'
export const currentConversationAtom = atom<IAReceivedConversation | undefined>(
  undefined
)
currentConversationAtom.debugLabel = 'currentConversationAtom'
const showChatModal = atom((get) => get(currentConversationAtom) !== undefined)
showChatModal.debugLabel = 'showChatModal'
const showCreateCroup = atom(false)
showCreateCroup.debugLabel = 'showCreateCroup'
const showGroupManage = atom(false)
showGroupManage.debugLabel = 'showGroupManage'
const showChatRecords = atom(false)
showChatRecords.debugLabel = 'showChatRecords'
export const conversationListAtom = atom<IAReceivedConversation[] | undefined>(
  undefined
)
conversationListAtom.debugLabel = 'conversationListAtom'
export const messageDataAtom = atom<GetHistoryMessageResult | undefined>(
  undefined
)
messageDataAtom.debugLabel = 'messageDataAtom'
export const isInitializedAtom = atom(false)
isInitializedAtom.debugLabel = 'isInitializedAtom'
export const chatRecordsAtom = atom<GetHistoryMessageResult | undefined>(
  undefined
)
chatRecordsAtom.debugLabel = 'chatRecordsAtom'
const userFriendsAtom = atom<IFriendItem[]>([])
userFriendsAtom.debugLabel = 'userFriendsAtom'
export const totalUnreadCountAtom = atom(0)
totalUnreadCountAtom.debugLabel = 'totalUnreadCountAtom'
const userGroupsAtom = atom<IGroupListItem[]>([])
userGroupsAtom.debugLabel = 'userGroupsAtom'
const showAddGroupMembersAtom = atom(false)
showAddGroupMembersAtom.debugLabel = 'showAddGroupMembersAtom'
const membersAtom = atom<IGroupMemberItem[]>([])
membersAtom.debugLabel = 'membersAtom'
const showDropGroupMembersAtom = atom(false)
showDropGroupMembersAtom.debugLabel = 'showDropGroupMembersAtom'
const personalGroupingAtom = atom(false)
personalGroupingAtom.debugLabel = 'personalGroupingAtom'
const resourceGroupListAtom = atom<IGroupListItem[]>([])
resourceGroupListAtom.debugLabel = 'resourceGroupListAtom'

export function useMessageAtom() {
  // 消息列表弹窗
  const [messageModalVisible, setMessageModal] = useAtom(showMessageModal)
  const toggleMessageModalVisible = (payload: boolean) => {
    setMessageModal(payload)

    if (!payload) {
      setConversation(undefined)
    }
  }

  // 当前聊天窗会话
  const [currentConversation, setCurrentConversation] = useAtom(
    currentConversationAtom
  )
  const [chatModalVisible] = useAtom(showChatModal)
  const setConversation = (payload: IAReceivedConversation | undefined) => {
    setCurrentConversation(payload)
    setCreateCroup(false)
    setChatRecords(false)
  }

  // 创建群聊
  const [createCroupVisible, setCreateCroup] = useAtom(showCreateCroup)

  // 群管理弹窗
  const [groupManageVisible, setGroupManage] = useAtom(showGroupManage)

  // 聊天记录
  const [chatRecordsVisible, setChatRecords] = useAtom(showChatRecords)

  // 导航消息群组
  const toggleMessageGroupVisible = (payload: boolean) => {
    console.log(payload)
  }

  // IM是否初始化成功标识
  const [isInitialized, setIsInitialized] = useAtom(isInitializedAtom)

  // 会话列表
  const [conversationList, setConversationList] = useAtom(conversationListAtom)

  // 具体会话数据
  const [messageData, setMessageData] = useAtom(messageDataAtom)

  // 聊天记录搜索
  const [chatRecordsData, setChatRecordsData] = useAtom(chatRecordsAtom)

  // 好友列表
  const [userFriends, setUserFriends] = useAtom(userFriendsAtom)

  // 未读消息数
  const [totalUnreadCount, setTotalUnreadCount] = useAtom(totalUnreadCountAtom)

  // 资源群组列表
  const [resourceGroupList, setResourceGroupList] = useAtom(
    resourceGroupListAtom
  )

  // 群组列表
  const [userGroups, setUserGroups] = useAtom(userGroupsAtom)

  // 群组成员列表
  const [members, setMembers] = useAtom(membersAtom)

  // 新增群组成员
  const [showAddGroupMembers, setShowAddGroupMembers] = useAtom(
    showAddGroupMembersAtom
  )

  // 删除群组成员
  const [showDropGroupMembers, setShowDropGroupMembers] = useAtom(
    showDropGroupMembersAtom
  )

  // 是否个人建群模式
  const [personalGrouping, setPersonalGrouping] = useAtom(personalGroupingAtom)

  return {
    messageModalVisible,
    toggleMessageModalVisible,
    currentConversation,
    setConversation,
    chatModalVisible,
    createCroupVisible,
    setCreateCroup,
    groupManageVisible,
    setGroupManage,
    chatRecordsVisible,
    setChatRecords,
    toggleMessageGroupVisible,
    conversationList,
    setConversationList,
    messageData,
    setMessageData,
    isInitialized,
    setIsInitialized,
    chatRecordsData,
    setChatRecordsData,
    userFriends,
    setUserFriends,
    totalUnreadCount,
    setTotalUnreadCount,
    userGroups,
    setUserGroups,
    showAddGroupMembers,
    setShowAddGroupMembers,
    members,
    setMembers,
    showDropGroupMembers,
    setShowDropGroupMembers,
    personalGrouping,
    setPersonalGrouping,
    resourceGroupList,
    setResourceGroupList
  }
}
