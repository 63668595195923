import styles from './index.module.less'

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div>Loading...</div>
    </div>
  )
}

export default Loading
