import { message } from 'antd'
import axios, {
  InternalAxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
  AxiosError
} from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'

import { store } from '@/store'
import { loginStateAtom, userAtom } from '@/store/user'
import { getGlobalConfig } from '@/utils'

const baseURL = getGlobalConfig().VITE_GLOB_API_URL

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  timeout: 10 * 60 * 1000
})

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const urlParams = qs.parse(window.location.search.slice(1))

    // 获取token
    const token = urlParams.token || localStorage.getItem('token')
    if (urlParams.token) {
      localStorage.setItem('token', urlParams.token as string)
    }

    // 添加token到请求头中
    if (token && config.url !== '/user/refreshToken') {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    // 添加渠道参数到请求头
    config.headers['APP-CHANNEL'] = 'web'
    config.headers['FB-FBC'] = Cookies.get('_fbc')
    config.headers['FB-FBP'] = Cookies.get('_fbp')

    if (store.get(loginStateAtom)) {
      config.headers['SENSOR-DISTINCT-ID'] = store.get(userAtom).id
    } else {
      const sensorsdata = Cookies.get('sensorsdata2015jssdkcross') || '{}'
      config.headers['SENSOR-DISTINCT-ID'] = JSON.parse(sensorsdata).distinct_id
    }

    return config
  },
  (error: unknown) => {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 对响应数据做点什么
    return response
  },
  (error: AxiosError) => {
    // 处理响应错误

    if (error.response?.status === 401) {
      // 登录前的页面 URL
      const currentUrl = window.location.href

      // 创建 URL 对象并删除 token 参数
      const url = new URL(currentUrl)
      url.searchParams.delete('token')
      const updatedUrl = url.toString()

      message.error('Login Failure')
      store.set(loginStateAtom, false)
      window.location.href = '/login'
      sessionStorage.setItem('redirectUrl', updatedUrl)
    } else {
      message.error('Request Failed')
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
