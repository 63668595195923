//...
import * as Sentry from '@sentry/react'

import { getGlobalConfig } from './utils'

const ENVIRONMENT = getGlobalConfig().VITE_ENVIRONMENT

if (!['dev', 'test'].includes(ENVIRONMENT)) {
  console.log(ENVIRONMENT, '执行sentry初始化')
  Sentry.init({
    dsn: 'https://f65aaeec1049b0cb9a4df67465b02480@o4507333596479488.ingest.us.sentry.io/4507333610831872',
    environment: ENVIRONMENT,
    release: '2.1.1',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
