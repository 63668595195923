import { get } from '@/httpRequest'

export interface IGroupResourceGroupListRequest {
  /**
   * 资源id
   */
  resource_id?: string
  /**
   * 资源类型，1Novel,2AdNovel,3剧集,4书单
   */
  resource_type?: string
}

export interface IGroupResourceGroupListResponse {
  count: number
  list: IGroupListItem[]
}

export interface IGroupListItem {
  name: string
  avatar: string
  group_code: string
}

export const getGroupResourceGroupList = async (
  params: IGroupResourceGroupListRequest
) => {
  const response = await get<IGroupResourceGroupListResponse>(
    `/im/group/resourceGroupList`,
    params
  )

  return response
}
