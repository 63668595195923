import { useMemo } from 'react'

import { IAReceivedConversation } from '@rongcloud/imlib-next'
import * as RongIMLib from '@rongcloud/imlib-next'

import { Personal } from '@/components/Personal'
import { useMessageAtom } from '@/store/message'

import { letters } from '../goup/CreateGroupStep1'
import styles from '../message.module.less'

export const Groups = () => {
  const { currentConversation, setConversation, userGroups } = useMessageAtom()

  const userGroupsData = useMemo(() => {
    const sortData = userGroups.sort(
      (a, b) => a.name.localeCompare(b.name || '') || 0
    )

    letters.forEach((preFix) => {
      const index = sortData.findIndex(
        (data) => data.name.toLocaleUpperCase()[0] === preFix
      )
      if (index !== -1) {
        sortData[index].preFix = preFix
      }
    })

    return sortData
  }, [userGroups])

  const openChat = (id: number | string) => {
    setConversation({
      targetId: String(id),
      conversationType: RongIMLib.ConversationType.GROUP
    } as unknown as IAReceivedConversation)
  }
  return (
    <div
      className={`${styles.ContactList} ${styles.ContactListRead}`}
      style={{ flex: 1, overflowY: 'scroll' }}
    >
      <ul>
        {userGroupsData.map((item) => (
          <>
            {item.preFix && <div className="letter">{item.preFix}</div>}
            <li
              key={item.group_code}
              className={
                currentConversation?.targetId === String(item.group_code)
                  ? 'active'
                  : ''
              }
              onClick={() => {
                console.log(item)
                openChat(item.group_code)
              }}
            >
              <Personal
                userData={{}}
                nickname={item.name}
                avatar={item.avatar}
              />
            </li>
          </>
        ))}
      </ul>
    </div>
  )
}
