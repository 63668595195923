import { useMemo } from 'react'

import { useLocation, useParams } from 'react-router-dom'

export const useResourceParams = () => {
  const queryParams = useParams()
  const location = useLocation()

  const params = useMemo(() => {
    const resourceInfo =
      (queryParams.resourceInfo || location.pathname.split('/').pop())?.split(
        '-'
      ) || []

    const resourceInfoObj = {
      type: resourceInfo[0],
      id: resourceInfo[1],
      nav: resourceInfo[2]
    }

    return resourceInfoObj
  }, [queryParams])

  return params
}
