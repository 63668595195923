import React, { useEffect } from 'react'

import { message } from 'antd'
import { useAtomValue } from 'jotai'
import { useLocation, useNavigate } from 'react-router-dom'

import { loginStateAtom } from '@/store/user'

export type TProps = {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<TProps> = ({ children }) => {
  const location = useLocation()
  const navigator = useNavigate()
  const isLogin = useAtomValue(loginStateAtom)
  // useEffect(() => {
  //   if (!isLogin && /home/.test(location.pathname)) {
  //     message.warning('Please Login')
  //     navigator('/login')
  //   }
  // }, [location.pathname, isLogin, navigator])

  return children
}

export default ProtectedRoute
