import { useEffect } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { useAtomValue } from 'jotai'

import { isSuccess } from '@/httpRequest'
import { getUserFriends } from '@/httpRequest/modules/imInfo/friends'
import { getGroupList } from '@/httpRequest/modules/imInfo/groupList'
import { getIMToken } from '@/httpRequest/modules/imInfo/imToken'
import { store } from '@/store'
import {
  currentConversationAtom,
  messageDataAtom,
  totalUnreadCountAtom,
  useMessageAtom
} from '@/store/message'
import { loginStateAtom, useUserIMInfoAtom } from '@/store/user'
import { getGlobalConfig } from '@/utils'

import { getConversationList, getTotalUnreadCount } from '../utils'

const APP_KEY = getGlobalConfig().VITE_RONG_CLOUD_APP_KEY
const AREA_CODE = getGlobalConfig().VITE_RONG_CLOUD_AREA_CODE

RongIMLib.init({ appkey: APP_KEY, areaCode: AREA_CODE })

const Events = RongIMLib.Events

export const useInitIM = () => {
  const { setUserIMInfo } = useUserIMInfoAtom()

  const {
    setUserFriends,
    setMessageData,
    setIsInitialized,
    setConversationList,
    setTotalUnreadCount,
    setUserGroups
  } = useMessageAtom()
  const isLogin = useAtomValue(loginStateAtom)

  const handleGetUserIMToken = async () => {
    if (!isLogin) {
      return
    }

    const resp = await getIMToken()
    if (resp && isSuccess(resp)) {
      setUserIMInfo(resp.data)
      RongIMLib.connect(resp.data.im_token).then((res) => {
        if (res.code === RongIMLib.ErrorCode.SUCCESS) {
          console.log('链接成功, 链接用户 id 为: ', res.data?.userId)
          // 设置初始化成功标识
          setIsInitialized(true)
        } else {
          console.warn('链接失败, code:', res.code)
          setIsInitialized(false)
        }
      })

      RongIMLib.addEventListener(Events.CONNECTING, () => {
        console.log('正在链接服务器')
      })

      RongIMLib.addEventListener(Events.CONNECTED, async () => {
        console.log('已经链接到服务器')
        const totalUnreadCount = await getTotalUnreadCount()
        setTotalUnreadCount(totalUnreadCount || 0)
        console.log(totalUnreadCount, '初始未读数')
      })

      RongIMLib.addEventListener(Events.MESSAGES, async (evt) => {
        console.log(evt.messages)
        console.log('收到了新消息')
        // const totalUnreadCount = await getTotalUnreadCount()
        // console.log(totalUnreadCount, '未读数')

        // 判断是否打开了消息面板
        const currentConversation = store.get(currentConversationAtom)
        const totalUnreadCountAtomData = store.get(totalUnreadCountAtom)
        if (!currentConversation) {
          store.set(totalUnreadCountAtom, totalUnreadCountAtomData + 1)
          console.log('标记新的未读数量')
        }

        // 更新会话列表
        const conversationListResp = await getConversationList()
        if (conversationListResp.success) {
          setConversationList(conversationListResp.data)
          console.log('更新会话列表')

          if (
            evt.messages.some(
              (item) =>
                item.conversationType === RongIMLib.ConversationType.GROUP &&
                item.messageType === 'RC:GrpNtf'
            )
          ) {
            handleGetUserGroups()
          }
        }

        // 下面处理具体消息内容
        // 获取到最新的消息数据，避免hook的闭包问题
        const messageData = store.get(messageDataAtom)

        const newConversationMessage: RongIMLib.IAReceivedMessage[] = []
        evt.messages.forEach((item) => {
          if (item.targetId === currentConversation?.targetId) {
            newConversationMessage.push(item)
          }
        })

        if (messageData) {
          messageData.list = [...messageData.list, ...newConversationMessage]
          setMessageData({ ...messageData })
        } else {
          setMessageData({
            list: newConversationMessage,
            hasMore: false
          })
        }
      })
    }
  }

  const handleGetUserFriends = async () => {
    if (!isLogin) {
      return
    }

    const resp = await getUserFriends({ page: 1, size: 1000 })

    if (resp && isSuccess(resp)) {
      setUserFriends(resp.data.list)
    }
  }

  const handleGetUserGroups = async () => {
    if (!isLogin) {
      return
    }

    const resp = await getGroupList({})

    if (isSuccess(resp)) {
      setUserGroups(resp.data.list)
    }
  }

  useEffect(() => {
    handleGetUserIMToken()
    handleGetUserFriends()
    handleGetUserGroups()
    return () => {
      RongIMLib.removeEventListeners(Events.CONNECTING)
      RongIMLib.removeEventListeners(Events.CONNECTED)
      RongIMLib.removeEventListeners(Events.MESSAGES)
    }
  }, [])
}
