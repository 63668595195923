import { useNavigate } from 'react-router-dom'

import { useMessageAtom } from '@/store/message'
import { useUserAtom } from '@/store/user'
import personal from '@img/header/personal.png'

interface Params {
  avatar?: string
  nickname?: string
  size?: 'normal' | 'small' | 'large'
  extra?: JSX.Element
  userData: unknown
}

// 人员头像组件
export function Personal({
  avatar,
  nickname = 'Victor Hugo',
  size = 'normal',
  extra,
  userData
}: Params) {
  const navigate = useNavigate()
  const { user } = useUserAtom()
  const { toggleMessageModalVisible } = useMessageAtom()
  const userCode = (userData as Record<string, string>)?.id
  const handleToPersonalInfoPage = () => {
    if (userCode && !['system', 'assistant', user.id].includes(userCode)) {
      toggleMessageModalVisible(false)
      navigate(`/people/${userCode}/publish`)
    }
  }

  return (
    <div
      className={[`c-author-${size}`].join('')}
      style={{ cursor: userCode ? 'pointer' : '' }}
    >
      <img
        src={avatar || personal}
        alt="Avatar"
        onClick={handleToPersonalInfoPage}
      />
      <div style={{ marginLeft: 10 }}>
        <span>{nickname}</span>
        {extra}
      </div>
    </div>
  )
}
