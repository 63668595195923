import React from 'react'

import { Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import qs from 'qs'

import { Personal } from '@/components/Personal'
import { isSuccess } from '@/httpRequest'
import { getGroupList } from '@/httpRequest/modules/imInfo/groupList'
import { getGroupResourceGroupList } from '@/httpRequest/modules/imInfo/groupResourceGroupList'
import {
  IIMGroupRequest,
  createIMGroup
} from '@/httpRequest/modules/message/group'
import { useResourceParams } from '@/pages/Detail/hooks/useResourceParams'
import UploadImg, { EFileType } from '@/pages/Publish/components/UploadImg'
import { useMessageAtom } from '@/store/message'
import { useUserAtom } from '@/store/user'

import styles from '../message.module.less'

interface IProps {
  change: (step: string) => void
  userIds: number[]
}

type FieldType = {
  name?: string
  avatar?: string
}
export const CreateGroupStep2: React.FC<IProps> = (props) => {
  const { change, userIds = [] } = props
  const [form] = useForm()
  const {
    setCreateCroup,
    userFriends,
    personalGrouping,
    setUserGroups,
    setResourceGroupList
  } = useMessageAtom()
  const { user } = useUserAtom()
  const searchInfo = useResourceParams()

  const handleGetGroupList = async () => {
    const resp = await getGroupList({})

    if (isSuccess(resp)) {
      setUserGroups(resp.data.list)
    }

    if (searchInfo.id && searchInfo.type) {
      const result = await getGroupResourceGroupList({
        resource_id: searchInfo.id as string,
        resource_type: searchInfo.type as string
      })

      if (isSuccess(result)) {
        setResourceGroupList(result.data.list)
      }
    }
  }

  const handleConfirm = async () => {
    const formVal = await form.validateFields()
    const request: IIMGroupRequest = {
      name: formVal.name,
      avatar: formVal.avatar,
      member_ids: [user.id as unknown as number, ...userIds]
    }
    if (!personalGrouping) {
      request.resource_id = searchInfo.id as string
      request.resource_type = searchInfo.type as string
    }
    const resp = await createIMGroup(request)
    if (resp && isSuccess(resp)) {
      message.success('success')
      setCreateCroup(false)
      handleGetGroupList()
    }
  }

  return (
    <>
      <div className="step2-left">
        <Form autoComplete="off" className="g-form" form={form}>
          <Form.Item<FieldType>
            name="name"
            rules={[{ required: true, message: 'Please enter the group name' }]}
          >
            <Input placeholder="Name of Group" />
          </Form.Item>
          <Form.Item<FieldType>
            valuePropName="fileUrl"
            name="avatar"
            rules={[{ required: true, message: 'Please upload group avatar' }]}
          >
            <UploadImg className="groupCover" type={EFileType.Avatar} />
          </Form.Item>
        </Form>
      </div>
      <div className="step2-right">
        <div className={`${styles.ContactList} scroll-box`}>
          <ul className={styles.columnAuthorList}>
            {userFriends.map((item) => {
              return userIds.includes(item.follower_id || NaN) ? (
                <li key={item.follower_id}>
                  <Personal
                    nickname={item.follower?.nickname}
                    userData={item.follower}
                  />
                </li>
              ) : null
            })}
          </ul>
        </div>
        {/* 按钮 */}
        <div className="footer">
          <button className="g-btn mr20" onClick={() => change('step1')}>
            Back
          </button>
          <button className="g-btn g-btn-primary mr20" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}
