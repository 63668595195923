import { post } from '@/httpRequest'

export interface IGroupAddRequest {
  /**
   * 群组id
   */
  group_id: string
  /**
   * 用户id，英文逗号分隔
   */
  user_ids: string
}

export const postGroupAdd = async (params: IGroupAddRequest) => {
  const response = await post<null>(`/im/group/add`, params)

  return response
}
