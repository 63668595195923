import { useEffect, useState } from 'react'

import * as RongIMLib from '@rongcloud/imlib-next'
import { IAReceivedConversation } from '@rongcloud/imlib-next'
import { DatePicker, Input } from 'antd'
import dayjs from 'dayjs'

import { Personal } from '@/components/Personal'
import { useMessageAtom } from '@/store/message'
import { useUserAtom } from '@/store/user'
import ArrowIcon from '@img/components/arrow.png'
import DelSrc from '@img/components/del.png'
import personal from '@img/header/personal.png'

import styles from '../message.module.less'
import { ERongCloudMessageType, getHistoryMessages } from '../utils'

export const ChatRecords = () => {
  const {
    setChatRecords,
    currentConversation,
    isInitialized,
    chatRecordsData,
    setChatRecordsData,
    userFriends
  } = useMessageAtom()
  const [showDate, setShowData] = useState<RongIMLib.IAReceivedMessage[]>()
  const [searchVal, setSearchVal] = useState<string>()
  const [searchDate, setSearchDate] = useState(0)
  const { user } = useUserAtom()

  const handleGetHistoryMessages = async (
    currentConversation: IAReceivedConversation
  ) => {
    const conversation = {
      conversationType: currentConversation.conversationType,
      targetId: currentConversation.targetId
    }

    const resp = await getHistoryMessages(conversation, {
      timestamp: dayjs(searchDate).endOf('day').valueOf(),
      count: 20
    })

    if (resp.success) {
      setChatRecordsData(resp.data)
    }
  }

  const handleGetUserInfo = (targetId: string) => {
    if (targetId === String(user.id)) {
      return {
        follower: {
          nickname: user.nickname || '',
          avatar: user.avatar_url || ''
        }
      }
    }
    return userFriends?.find?.(
      (friend) => friend.follower?.user_code === targetId
    )
  }

  useEffect(() => {
    const data = chatRecordsData?.list.filter((item) => {
      return item.content.content?.indexOf?.(searchVal || '') !== -1
    })

    setShowData(data)
  }, [searchVal, chatRecordsData])

  useEffect(() => {
    if (isInitialized && currentConversation) {
      handleGetHistoryMessages(currentConversation)
    }
  }, [isInitialized, currentConversation, searchDate])

  return (
    <div className={styles.ChatRecords}>
      {/* 头部栏 */}
      <div className="header">
        <span className="c-white">Chat records</span>
        <a onClick={() => setChatRecords(false)}>
          <img src={DelSrc} width={18} />
        </a>
      </div>

      {/* 搜索栏 */}
      <div className="query-params">
        <div className="input-line">
          <Input
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
          />
        </div>
        <DatePicker
          className="g-datePicker"
          placeholder=""
          suffixIcon={<img src={ArrowIcon} width={16} />}
          onChange={(value) => {
            setSearchDate(value?.valueOf() || 0)
          }}
        />
      </div>

      {/* 列表 */}
      <div className="list-box">
        {showDate?.map((item) => {
          if (item.messageType === ERongCloudMessageType.TxtMsg) {
            return (
              <div className="list-item" key={item.messageId}>
                <div className="flx-justify-between mb10">
                  <Personal
                    size={'small'}
                    userData={item}
                    nickname={
                      handleGetUserInfo(item.senderUserId)?.follower?.nickname
                    }
                    avatar={
                      handleGetUserInfo(item.senderUserId)?.follower?.avatar ||
                      personal
                    }
                  />
                  <span className="f12 c-80">
                    {dayjs(item.sentTime).format('YYYY-MM-DD')}
                  </span>
                </div>
                {/* 文字消息 */}
                <div className="message-text">{item.content.content}</div>
              </div>
            )
          }

          if (item.messageType === ERongCloudMessageType.ImgMsg) {
            return (
              <div className="list-item" key={item.messageId}>
                <div className="flx-justify-between mb10">
                  <Personal
                    size={'small'}
                    nickname={
                      handleGetUserInfo(item.senderUserId)?.follower?.nickname
                    }
                    avatar={
                      handleGetUserInfo(item.senderUserId)?.follower?.avatar ||
                      personal
                    }
                    userData={{}}
                  />
                  <span className="f12 c-80">
                    {dayjs(item.sentTime).format('YYYY-MM-DD')}
                  </span>
                </div>
                {/* 图片消息 */}
                <img src={item.content.imageUri} className="message-image" />
              </div>
            )
          }
          return null
        })}
        {/* 讨论消息 */}
        {/* <div className="list-item">
          <div className="flx-justify-between mb10">
            <Personal size={'small'} />
            <span className="f12 c-80">2023-11-15</span>
          </div>

          <div className="message-media">
            <img src={Discuss} width={50} />
            <div className="infos">
              <div className="c-white sle mb4">The Miserable World</div>
              <div className="f12 c-white sle mb10">The Miserable World</div>
              <div className="f10 c-80">Community</div>
            </div>
          </div>
        </div> */}

        {/* 视频消息 */}
        {/* <div className="list-item">
          <div className="flx-justify-between mb10">
            <Personal size={'small'} />
            <span className="f12 c-80">2023-11-15</span>
          </div>
          <div className="message-media">
            <img src={ImgSrc} width={50} />
            <img src={Play} width={30} className="play-icon" />
            <div className="infos">
              <div className="c-white sle mb4">The Miserable World</div>
              <div className="f12 c-white sle mb10">The Miserable World</div>
              <div className="f10 c-80">Video</div>
            </div>
          </div>
        </div> */}

        {/* 视频消息 */}
        {/* <div className="list-item">
          <div className="flx-justify-between mb10">
            <Personal size={'small'} />
            <span className="f12 c-80">2023-11-15</span>
          </div>

          <div className="message-media">
            <img src={ImgSrc} width={50} />
            <div className="infos">
              <div className="c-white sle mb4">The Miserable World</div>
              <div className="f12 c-white sle mb10">The Miserable World</div>
              <div className="f10 c-80">Story</div>
            </div>
          </div>
        </div> */}

        {/* 书单消息 */}
        {/* <div className="list-item">
          <div className="flx-justify-between mb10">
            <Personal size={'small'} />
            <span className="f12 c-80">2023-11-15</span>
          </div>

          <div className="message-media">
            <img src={StoryList} width={50} />
            <div className="infos">
              <div className="c-white sle mb4">The Miserable World</div>
              <div className="f12 c-white sle mb10">The Miserable World</div>
              <div className="f10 c-80">Community</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
