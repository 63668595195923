import { get } from '@/httpRequest'

export interface IGroupListRequest {
  /**
   * 资源id
   */
  resource_id?: string
  /**
   * 资源类型，1Novel,2AdNovel,3剧集,4书单
   */
  resource_type?: string
}

export interface IGroupListResponse {
  count: number
  list: IGroupListItem[]
}

export interface IGroupListItem {
  preFix?: string
  name: string
  avatar: string
  group_code: string
}

export const getGroupList = async (params: IGroupListRequest) => {
  const response = await get<IGroupListResponse>(`/im/user/groupList`, params)

  return response
}
