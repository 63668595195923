import * as RongIMLib from '@rongcloud/imlib-next'

import { store } from '@/store'
import { userAtom } from '@/store/user'

export type TConversation = {
  targetId: string
  conversationType: RongIMLib.ConversationType
}

export type TResult<T> =
  | { success: true; data: T | undefined; error: null }
  | { success: false; data: null; error: Error }

/**
 * 融云消息类型枚举
 */
export enum ERongCloudMessageType {
  /**
   * 文本消息
   */
  TxtMsg = 'RC:TxtMsg',

  /**
   * 语音消息
   */
  VcMsg = 'RC:VcMsg',

  /**
   * 高质量语音消息
   */
  HQVCMsg = 'RC:HQVCMsg',

  /**
   * 图片消息
   */
  ImgMsg = 'RC:ImgMsg',

  /**
   * GIF 图片消息
   */
  GIFMsg = 'RC:GIFMsg',

  /**
   * 图文消息
   */
  ImgTextMsg = 'RC:ImgTextMsg',

  /**
   * 文件消息
   */
  FileMsg = 'RC:FileMsg',

  /**
   * 位置消息
   */
  LBSMsg = 'RC:LBSMsg',

  /**
   * 小视频消息
   */
  SightMsg = 'RC:SightMsg',

  /**
   * 合并转发消息
   */
  CombineMsg = 'RC:CombineMsg'
}

class IMError extends Error {
  constructor(
    public operation: string,
    public targetId: string,
    message?: string
  ) {
    super(message)
    this.name = 'IMError'
  }
}

// 错误处理函数
const handleError = <T>(
  error: Error,
  operation: string,
  targetId: string = ''
): TResult<T> => {
  console.error(`${operation}失败`, error)
  let errorMessage = ''
  if (error instanceof Error) {
    errorMessage = error.message
  }
  return {
    success: false,
    data: null,
    error: new IMError(operation, targetId, errorMessage)
  } as TResult<T>
}

// 通用函数
const performOperation = async <T extends RongIMLib.IAsyncRes<unknown>>(
  operation: string,
  action: () => Promise<T>,
  conversation?: TConversation
): Promise<TResult<T['data']>> => {
  try {
    const resp = await action()
    if (resp.code !== 0) {
      throw new Error('Operation failed')
    }
    return { success: true, data: resp.data, error: null }
  } catch (error) {
    return handleError(error as Error, operation, conversation?.targetId)
  }
}

const setUser = <T extends { user?: RongIMLib.IUserInfo['user'] }>(
  msgBody: T
) => {
  const userInfo = store.get(userAtom) || {}
  const otherUserInfo = msgBody.user || {}
  msgBody.user = {
    ...otherUserInfo,
    ...userInfo
  } as unknown as RongIMLib.IUserInfo['user']
  return msgBody
}

export const sendMessage = async (
  conversation: TConversation,
  content: string
): Promise<TResult<RongIMLib.IAReceivedMessage>> => {
  const msgBody = {
    content,
    user: { id: '', name: '', extra: '' }
  }
  const message = new RongIMLib.TextMessage(setUser(msgBody))
  return performOperation(
    'sendMessage',
    () => RongIMLib.sendMessage(conversation, message),
    conversation
  )
}

export const sendImgMessage = async (
  conversation: TConversation,
  msgBody: RongIMLib.ISendBody,
  hooks?: RongIMLib.IUploadHooks,
  sendOptions?: RongIMLib.IImageMessageOption
) => {
  msgBody.user = { id: '', name: '', extra: '' }
  return performOperation(
    'sendImgMessage',
    () =>
      RongIMLib.sendImageMessage(
        conversation,
        setUser(msgBody),
        hooks,
        sendOptions
      ),
    conversation
  )
}

export const sendFileMessage = async (
  conversation: TConversation,
  msgBody: RongIMLib.ISendBody,
  hooks?: RongIMLib.IUploadHooks,
  sendOptions?: RongIMLib.IImageMessageOption
) => {
  msgBody.user = { id: '', name: '', extra: '' }
  return performOperation(
    'sendFileMessage',
    () =>
      RongIMLib.sendFileMessage(
        conversation,
        setUser(msgBody),
        hooks,
        sendOptions
      ),
    conversation
  )
}

export const snedShareMessage = async (
  conversation: TConversation,
  msgBody: RongIMLib.ITextMessageBody
) => {
  const message = new RongIMLib.TextMessage(setUser(msgBody))
  return performOperation(
    'sendMessage',
    () => RongIMLib.sendMessage(conversation, message),
    conversation
  )
}

export const getHistoryMessages = async (
  conversation: TConversation,
  option: RongIMLib.GetHistoryMessageOption = {
    timestamp: +new Date(),
    count: 20
  }
): Promise<TResult<RongIMLib.GetHistoryMessageResult>> => {
  return performOperation(
    'getHistoryMessages',
    () => RongIMLib.getHistoryMessages(conversation, option),
    conversation
  )
}

export const getConversationList = async (): Promise<
  TResult<RongIMLib.IAReceivedConversation[]>
> => {
  return performOperation('getConversationList', () =>
    RongIMLib.getConversationList()
  )
}

export const getUnreadConversationList = async (): Promise<
  TResult<RongIMLib.IAReceivedConversation[]>
> => {
  const conversationTypes = [
    RongIMLib.ConversationType.PRIVATE,
    RongIMLib.ConversationType.GROUP,
    RongIMLib.ConversationType.SYSTEM
  ]

  return performOperation('getUnreadConversationList', () =>
    RongIMLib.getUnreadConversationList(conversationTypes)
  )
}

export const getTotalUnreadCount = async () => {
  const includeMuted = false
  const conversationTypes = [
    RongIMLib.ConversationType.PRIVATE,
    RongIMLib.ConversationType.GROUP
  ]
  const res = await RongIMLib.getTotalUnreadCount(
    includeMuted,
    conversationTypes
  )
  if (res.code === 0) {
    return res.data
  }
  return
}

export const clearHistoryMessages = async (
  targetId: string,
  type?: RongIMLib.ConversationType
) => {
  const conversation = {
    conversationType: type || RongIMLib.ConversationType.PRIVATE,
    targetId
  }

  const res = await RongIMLib.clearHistoryMessages(
    conversation,
    new Date().getTime()
  )

  return res.code === 0
}

export const removeConversation = async (
  targetId: string,
  type?: RongIMLib.ConversationType
) => {
  // targetId = '接收方的 userId'
  const conversation = {
    conversationType: type || RongIMLib.ConversationType.PRIVATE,
    targetId
  }

  const res = await RongIMLib.removeConversation(conversation)

  // 删除指定会话成功
  return res.code === 0
}
