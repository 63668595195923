import { useEffect, useMemo } from 'react'

import { Layout } from 'antd'
import device from 'current-device'
import { useAtomValue, useSetAtom } from 'jotai'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import { Index as LayoutMessage } from '@/components/Message/Index'
import { isSuccess } from '@/httpRequest'
import { getUserInfo } from '@/httpRequest/modules/user/userInfo'
import { loginStateAtom, useUserAtom } from '@/store/user'
import { trackFBPageViewEvent } from '@/utils/trackFB'

import LayoutFooter from './components/Footer'
import LayoutHeader from './components/Header'

import './index.less'

interface Layout {
  children?: JSX.Element | null
}

const WHITE_LIST_PATH = ['/publish', '/personal']

const LayoutIndex = ({ children }: Layout) => {
  const urlParams = qs.parse(window.location.search.slice(1))
  const location = useLocation()

  const showAction = useMemo(() => {
    const hiddenActionPath =
      location.pathname.includes('login') ||
      location.pathname.includes('register')
    return !hiddenActionPath
  }, [location])

  const { updateUser } = useUserAtom()
  const isLogin = useAtomValue(loginStateAtom)
  const setLoginStatus = useSetAtom(loginStateAtom)

  const getUserInfoData = async () => {
    if (!isLogin && !urlParams.token) {
      return
    }
    const resp = await getUserInfo()
    if (resp && isSuccess(resp)) {
      updateUser(resp.data)
      setLoginStatus(true)
    }
  }

  const handlePageViewEvent = () => {
    if (window.fbq) {
      trackFBPageViewEvent()
    }
  }

  const handleDeviceJump = () => {
    const isMobileOrTablet = device.mobile() || device.tablet()
    const currentHostname = window.location.hostname
    const isMobileDomain = /^m\./.test(currentHostname)
    console.log(isMobileOrTablet, isMobileDomain)

    const isWhiteListPath = WHITE_LIST_PATH.some((item) =>
      window.location.pathname.includes(item)
    )

    if (isMobileOrTablet && !isMobileDomain && !isWhiteListPath) {
      // 构建新的 URL
      const mobileHostname = `m.${currentHostname.replace(/^www\./, '')}`
      const newUrl = new URL(window.location.href)
      newUrl.hostname = mobileHostname

      // 重定向到移动站点，保留路径和查询参数
      window.location.href = newUrl.toString()
    }
  }

  useEffect(() => {
    getUserInfoData()
    handlePageViewEvent()
    handleDeviceJump()
  }, [location])

  return (
    <div className="container">
      <Layout>
        <LayoutHeader showAction={showAction}></LayoutHeader>
        <div className="main-app">{children ? children : ''}</div>
        <LayoutFooter></LayoutFooter>

        {/* 消息模块 */}
        {showAction && <LayoutMessage />}
      </Layout>
    </div>
  )
}

export default LayoutIndex
