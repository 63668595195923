import React from 'react'

import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Personal } from '@/components/Personal'
import { HOME_URL } from '@/config'
import { userLogout } from '@/httpRequest/modules/user/logout'
import { useSearchValAtom } from '@/store/home'
import { useStoryPageModeAtom } from '@/store/publish'
import { loginStateAtom, useUserAtom } from '@/store/user'
import { getGlobalConfig } from '@/utils'
import {
  handlePublishClickTrack,
  handleSearchInputTrack
} from '@/utils/trackSensors'
import edit from '@img/header/edit.png'
import personal from '@img/header/personal.png'
import search from '@img/header/search.png'
import imgAvatar from '@img/home/img.png'
import logo from '@img/logo.png'
import './index.less'

interface Header {
  showAction?: boolean
}

const ENVIRONMENT = getGlobalConfig().VITE_ENVIRONMENT
const helpCenterUrl = ['test', 'dev'].includes(ENVIRONMENT)
  ? 'https://help.test.mespery.com/'
  : 'https://help.mespery.com'

const LayoutHeader = ({ showAction = false }: Header) => {
  const navigate = useNavigate()
  const location = useLocation()
  const setLoginStatus = useSetAtom(loginStateAtom)
  const isLogin = useAtomValue(loginStateAtom)
  const { user } = useUserAtom()
  const { isEdit, handleEditCheck } = useStoryPageModeAtom()
  const { setSearchVal } = useSearchValAtom()

  const handleNavigateCheck = (callback: () => void) => {
    if (location.pathname.includes('/publish')) {
      handleEditCheck(isEdit, callback)
    } else {
      callback()
    }
  }

  const logout = async () => {
    const logoutResult = await userLogout()
    if (logoutResult) {
      setLoginStatus(false)
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('refreshToken')
      navigate('/login')
    }
  }
  const publishMenus: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          rel="noopener noreferrer"
          className="global-header-dropdown-link"
          onClick={() => {
            handleNavigateCheck(() => {
              if (location.pathname === '/publish') {
                window.history.replaceState({}, '', '/publish')
                window.location.reload()
              } else {
                navigate('/publish')
              }
            })
          }}
        >
          <h4>Story</h4>
          <p>Novel &amp; Reel</p>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div
          rel="noopener noreferrer"
          className="global-header-dropdown-link"
          onClick={() => {
            handleNavigateCheck(() => {
              if (location.pathname === '/publish/book') {
                window.history.replaceState({}, '', '/publish/book')
                window.location.reload()
              } else {
                navigate('/publish/book')
              }
            })
          }}
        >
          <h4>Book List</h4>
          <p>Recommend Stories</p>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div
          rel="noopener noreferrer"
          className="global-header-dropdown-link"
          onClick={() => {
            handleNavigateCheck(() => {
              if (location.pathname === '/publish/discuss') {
                window.history.replaceState({}, '', '/publish/discuss')
                window.location.reload()
              } else {
                navigate('/publish/discuss')
              }
            })
          }}
        >
          <h4>Discuss</h4>
          <p>Chat With The Community</p>
        </div>
      )
    }
  ]

  const userMenus: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          rel="noopener noreferrer"
          className="global-header-dropdown-link"
          onClick={() => {
            handleNavigateCheck(() => {
              navigate('/profile')
            })
          }}
        >
          <h4>Profile</h4>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div
          rel="noopener noreferrer"
          className="global-header-dropdown-link"
          onClick={() => {
            handleNavigateCheck(() => {
              window.open(helpCenterUrl)
            })
          }}
        >
          <h4>Support</h4>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <a
          rel="noopener noreferrer"
          onClick={() => {
            handleNavigateCheck(() => {
              logout()
            })
          }}
          className="global-header-dropdown-link"
        >
          <h4>Logout</h4>
        </a>
      )
    }
  ]

  return (
    <header className="global-layout-header">
      <div
        className="logo"
        onClick={() => {
          handleNavigateCheck(() => {
            navigate(HOME_URL)
          })
        }}
      >
        <img src={logo} alt="logo" width="142" />
      </div>
      {showAction && (
        <div className="flx-align-center actions">
          <img src={search} alt="search" width="15" className="search" />
          <input
            onKeyDown={(e) => {
              console.log(e.key)
              if (e.key === 'Enter') {
                navigate('/searchResult')
              }
            }}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
            onFocus={() => {
              handleSearchInputTrack()
            }}
          />
          {isLogin ? (
            <div>
              <Dropdown
                menu={{ items: publishMenus }}
                getPopupContainer={(): HTMLElement =>
                  document.querySelector('header-publish-wraper') as HTMLElement
                }
                placement="bottom"
                overlayClassName="global-header-dropdown-publish"
                onOpenChange={(open) => {
                  if (open) {
                    handlePublishClickTrack()
                  }
                }}
              >
                <div className="header-publish-wraper">
                  <button className="g-btn g-btn-primary btn-publish">
                    Publish
                  </button>
                  <img src={edit} alt="edit" width="36" className="edit" />
                </div>
              </Dropdown>
            </div>
          ) : (
            <Link to="/login" className="g-btn g-btn-primary">
              Log in
            </Link>
          )}
          <div className="personal">
            {isLogin && (
              <Dropdown
                menu={{ items: userMenus }}
                dropdownRender={(menu) => (
                  <div className="global-header-dropdown-user">
                    <div className="global-header-user">
                      <Personal
                        avatar={user.avatar_url || personal}
                        nickname={user.nickname || ''}
                        userData={user}
                      />
                    </div>
                    {React.cloneElement(menu as React.ReactElement)}
                  </div>
                )}
                getPopupContainer={(): HTMLElement =>
                  document.querySelector('header-user-wraper') as HTMLElement
                }
                placement="bottom"
              >
                <a className="header-user-wraper">
                  <img
                    src={user.avatar_url || personal}
                    width="30"
                    className="avatar-img"
                  />
                </a>
              </Dropdown>
            )}
          </div>
        </div>
      )}
    </header>
  )
}

export default LayoutHeader
