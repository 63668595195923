import { useEffect } from 'react'

import { message } from 'antd'
import qs from 'qs'

import { isSuccess } from '@/httpRequest'
import { postTransactionConfirm } from '@/httpRequest/modules/payment/transactionConfirm'
import { getUserInk } from '@/httpRequest/modules/user/userInk'
import { store } from '@/store'
import { userAtom } from '@/store/user'

const useVerifyPaymentResult = () => {
  const handleVerifyPaymentResult = async () => {
    const searchInfo = qs.parse(window.location.search.slice(1))
    console.log('空中云汇充值结果校验', searchInfo)
    if (!searchInfo.payment_id) return

    const resp = await postTransactionConfirm(
      searchInfo.payment_id as string,
      searchInfo.payment_method as string
    )

    if (!isSuccess(resp)) {
      return message.error(resp?.message)
    }

    const userInkResp = await getUserInk()
    if (!isSuccess(userInkResp)) {
      return
    }
    const user = store.get(userAtom)
    store.set(userAtom, {
      ...user,
      ...userInkResp?.data
    })

    message.success('Payment Success')
  }

  useEffect(() => {
    handleVerifyPaymentResult()
  }, [])
}

export default useVerifyPaymentResult
