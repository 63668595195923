import { post } from '@/httpRequest'

export interface IGroupModifyGroupNameRequest {
  group_id: string
  name: string
}

export const postGroupModifyGroupName = async (
  params: IGroupModifyGroupNameRequest
) => {
  const response = await post<null>(`/im/group/modifyGroupName`, params)

  return response
}
