import { get } from '@/httpRequest'

export interface IStoryListRequest {
  page?: number
  size?: number
  /**
   * 标签id，用英文逗号分隔
   */
  tag_ids?: string
}

export interface IStoryListResponse {
  count: number
  list: IStoryListItem[]
}

/** adNovel发布审核状态枚举 */
export enum EAdReviewStatus {
  /** 待审核 */
  REVIEW_STATUS_WAIT = 1,
  /** 审核通过 */
  REVIEW_STATUS_PASS = 2,
  /** 审核拒绝 */
  REVIEW_STATUS_REJECT = 3
}

/** 作品的状态 */
export enum EWorkStatus {
  /** 草稿状态 */
  DRAFT = 1,
  /** 已发布状态 */
  PUBLISHED = 2,
  /** 已下架状态 */
  OFFLINE = 3,
  /** adNGvel 跳转次数用完,下架状态 */
  AD_NGVEL_EXHAUSTED = 4
}

/** 签约状态 */
export enum EContractStatus {
  /** 初始状态,发起申请 */
  INITIAL = 'init',
  /** 等待状态,申请通过 */
  PENDING = 'wait',
  /** 已完成状态,已签约 */
  COMPLETED = 'done',
  /** 拒绝状态,拒绝申请 */
  REJECTED = 'reject'
}

/** adNovel 发布审核状态 */
export enum EAdNovelReviewStatus {
  /** 待审核 */
  PENDING = 1,
  /** 审核通过 */
  APPROVED = 2,
  /** 审核拒绝 */
  REJECTED = 3
}

/** 完结审核状态 */
export enum ECompletionReviewStatus {
  /** 待审核 */
  PENDING = 1,
  /** 审核通过 */
  APPROVED = 2,
  /** 审核拒绝 */
  REJECTED = 3
}

/** 解锁费用状态 */
export enum EUnlockFeeStatus {
  /** 免费 */
  FREE = 0,
  /** 入 V(有其中一章入 V 就算) */
  VIP_REQUIRED = 1
}

/** Reel 发布审核状态 */
export enum EReelReviewStatus {
  /** 待审核 */
  PENDING = 1,
  /** 审核通过 */
  APPROVED = 2,
  /** 审核拒绝 */
  REJECTED = 3
}

/** adReel 发布审核状态 */
export enum EAdReelReviewStatus {
  /** 待审核 */
  PENDING = 1,
  /** 审核通过 */
  APPROVED = 2,
  /** 审核拒绝 */
  REJECTED = 3
}

export interface IStoryListItem {
  review_status: EReelReviewStatus
  is_vip: number
  ad_review_status: EAdNovelReviewStatus
  finish_review_status: ECompletionReviewStatus
  sign_status: EContractStatus
  is_ad: boolean
  intro: string
  is_star: boolean
  is_like: boolean
  story_list_id?: string
  updated_at: number
  comm_id?: string
  resource_id?: string
  type: number
  novel_id?: string
  /**
   * 作者名
   */
  author_name: string
  /**
   * 是否已完结，false否true是
   */
  is_finish: boolean
  /**
   * 是否加热度，false否true是
   */
  is_hot: boolean
  /**
   * 是否需要付费，false否true是
   */
  is_pay: boolean
  /**
   * 是否已签约，false否true是
   */
  is_sign: boolean
  /**
   * 点赞数
   */
  like_num: number
  /**
   * 主图链接
   */
  cover_pic_url: string
  /**
   * 故事名
   */
  name: string
  /**
   * 阅读数
   */
  read_num: number
  /**
   * 故事资源id
   */
  source_id: string
  /**
   * 收藏数
   */
  star_num: number
  /**
   * 故事类型，novel小说，reel剧集，adstory外链
   */
  resource_type: number

  rec_data: IRecDatum[]

  is_author_pinned: number

  is_pinned: number

  title: string

  content: string

  status: EWorkStatus

  published_at?: number

  author_avatar?: string

  ad_jump_times?: number
}

export interface IRecDatum {
  cover_pic_url: string
  item_no: number
  rec_resource_id: number | string
  rec_resource_name: string
  rec_resource_type: number
  resource_author_name: string
  type: number
}

export const getStoryList = async (params: IStoryListRequest) => {
  const response = await get<IStoryListResponse>('/resource/story', params)

  return response
}
