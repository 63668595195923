import { Link } from 'react-router-dom'
import './index.less'

const LayoutFooter = () => {
  return (
    <footer className="global-layout-footer">
      <div>
        <Link to="/about" target="_blank" rel="noreferrer">
          About Us
        </Link>
        <Link to="/communityguidelines" target="_blank" rel="noreferrer">
          Community Guidelines
        </Link>
      </div>
      <div className="footer-info">&#169; 2024 Mespery Ltd</div>
    </footer>
  )
}

export default LayoutFooter
