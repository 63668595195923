import { useEffect } from 'react'

import { ConversationType, IAReceivedConversation } from '@rongcloud/imlib-next'
import { Badge } from 'antd'

import { getConversationList } from '@/components/Message/utils'
import { Personal } from '@/components/Personal'
import { useMessageAtom } from '@/store/message'
import personal from '@img/header/personal.png'

import styles from '../message.module.less'

export const Messages = () => {
  const {
    currentConversation,
    setConversation,
    conversationList,
    setConversationList,
    isInitialized,
    userFriends,
    messageModalVisible,
    setTotalUnreadCount,
    userGroups
  } = useMessageAtom()

  const openChat = (id: IAReceivedConversation) => {
    setConversation(id)
  }

  const handleGetConversationList = async () => {
    const resp = await getConversationList()
    console.log(resp, '会话列表')
    if (resp.success) {
      const count = resp.data?.reduce((previousValue, currentValue) => {
        if (currentValue.unreadMessageCount) {
          return previousValue + currentValue?.unreadMessageCount
        }
        return previousValue
      }, 0)
      console.log('所有会话列表中的未读数', count)
      setTotalUnreadCount(count || 0)
      setConversationList(resp.data)
    }
  }

  const handleGetUserInfo = (targetId: string) => {
    return userFriends?.find?.(
      (friend) => friend.follower?.user_code === targetId
    )
  }

  const handleGetGroupInfo = (targetId: string) => {
    return userGroups?.find?.((group) => group.group_code === targetId)
  }

  const handleRenderListItem = (item: IAReceivedConversation) => {
    const personalInfo = handleGetUserInfo(item.targetId)
    const groupInfo = handleGetGroupInfo(item.targetId)

    let nickName = ''
    let avatar = ''
    let lastMessage = ''

    if (item.conversationType === ConversationType.PRIVATE) {
      nickName = personalInfo?.follower?.nickname || ''
      avatar = personalInfo?.follower?.avatar || ''
      lastMessage = item.latestMessage?.content?.content
    }

    if (item.conversationType === ConversationType.GROUP) {
      nickName = groupInfo?.name || ''
      avatar = groupInfo?.avatar || ''
      lastMessage =
        item.latestMessage?.messageType === 'RC:TxtMsg'
          ? item.latestMessage?.content?.content
          : item.latestMessage?.content?.message
    }
    return (
      <li
        key={`${item.conversationType}_${item.targetId}`}
        className={
          currentConversation?.targetId === item.targetId &&
          currentConversation?.conversationType === item.conversationType
            ? 'active'
            : ''
        }
        onClick={() => openChat(item)}
      >
        <Badge count={item.unreadMessageCount} offset={[-285, 8]}>
          <Personal
            nickname={nickName}
            avatar={avatar || personal}
            extra={<div className="intro">{String(lastMessage)}</div>}
            userData={item}
          />
        </Badge>
      </li>
    )
  }

  useEffect(() => {
    if (isInitialized && messageModalVisible) {
      handleGetConversationList()
    }
  }, [isInitialized, messageModalVisible])

  return (
    <div
      className={styles.ContactList}
      style={{ flex: 1, overflowY: 'scroll' }}
    >
      <ul>{conversationList?.map((item) => handleRenderListItem(item))}</ul>
    </div>
  )
}
