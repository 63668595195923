import { useEffect, useState } from 'react'

import { useMessageAtom } from '@/store/message'
import { isClickInsideElement } from '@/utils'

import { Chatbox } from './chatbox/Chatbox'
import { ContactsModal } from './contacts/ContactsModal'
import { ChaModalContext } from './context'
import { CreateGroup } from './goup/CreateGroup'
import { useInitIM } from './hooks/useInitIM'
import styles from './message.module.less'

export const Index = () => {
  const {
    messageModalVisible,
    chatModalVisible,
    createCroupVisible,
    currentConversation,
    setGroupManage,
    groupManageVisible,
    setShowAddGroupMembers,
    setShowDropGroupMembers
  } = useMessageAtom()

  useInitIM()

  const [showChatModal, setShowChatModal] = useState(false)

  const onClickPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = document.getElementById('groupManageEle')
    if (targetElement && isClickInsideElement(targetElement, event)) {
      console.log('点击发生在目标元素内部')
      return
    } else if (groupManageVisible) {
      setGroupManage(false)
    }
  }

  useEffect(() => {
    setShowChatModal(chatModalVisible)
    setShowAddGroupMembers(false)
    setShowDropGroupMembers(false)
  }, [currentConversation])

  return (
    <ChaModalContext.Provider
      value={{
        onClose: () => {
          setShowChatModal(false)
        }
      }}
    >
      {/* 背景半透明遮罩 */}
      <div
        className={styles.messageCover}
        style={{
          opacity: messageModalVisible ? 1 : 0,
          zIndex: messageModalVisible ? (createCroupVisible ? 700 : 600) : -1
        }}
        onClick={onClickPanel}
      ></div>

      {/* 通讯录 */}
      <ContactsModal />

      {/* 聊天框 */}
      {showChatModal && <Chatbox />}

      {/* 创建群聊 */}
      {createCroupVisible && <CreateGroup />}
    </ChaModalContext.Provider>
  )
}
