import { atom, useAtom } from 'jotai'

const globalLoadingAtom = atom(false)
globalLoadingAtom.debugLabel = 'globalLoadingAtom'

export const useGlobalLoading = () => {
  const [globalLoading, setGlobalLoading] = useAtom(globalLoadingAtom)

  return {
    globalLoading,
    setGlobalLoading
  }
}
