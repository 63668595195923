import styles from '../message.module.less'

export interface ISearchInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  onChange,
  value
}) => {
  return (
    <input className={styles.SearchInput} onChange={onChange} value={value} />
  )
}
