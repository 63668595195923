import { post } from '@/httpRequest'

export interface IGroupModifyGroupAvatarRequest {
  avatar: string
  group_id: string
}

export const postGroupModifyGroupAvatar = async (
  params: IGroupModifyGroupAvatarRequest
) => {
  const response = await post<null>(`/im/group/modifyGroupAvatar`, params)

  return response
}
