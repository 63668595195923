import { Modal } from 'antd'
import { atom, useAtom } from 'jotai'
import qs, { ParsedQs } from 'qs'
import { useLocation } from 'react-router-dom'

export enum EPageNav {
  Step1 = '1',
  Step2 = '2',
  Step3 = '3'
}

// 创建一个Jotai原子来存储当前的步骤
const currentStepAtom = atom(EPageNav.Step1)
currentStepAtom.debugLabel = 'currentStepAtom'
const currentStepAtom2 = atom(EPageNav.Step2)
currentStepAtom2.debugLabel = 'currentStepAtom2'

const currentStepAtom3 = atom(EPageNav.Step3)
currentStepAtom3.debugLabel = 'currentStepAtom3'

// 减少顶部步骤：非新增，从第二步开始
export const useCurrentStepAtom = () => {
  const [navKey, setNavKey] = useAtom(currentStepAtom)

  return {
    navKey,
    setNavKey
  }
}

const currentStoryAtom = atom(1)
currentStoryAtom.debugLabel = 'currentStoryAtom'

export const useCurrentStoryAtom = () => {
  const [currentStory, setCurrentStory] = useAtom(currentStoryAtom)

  return {
    currentStory,
    setCurrentStory
  }
}

const novelOrReelIdAtom = atom('')
novelOrReelIdAtom.debugLabel = 'novelOrReelIdAtom'

export const useNovelOrReelIdAtom = () => {
  const [novelOrReelId, setNovelOrReelId] = useAtom(novelOrReelIdAtom)

  return {
    novelOrReelId,
    setNovelOrReelId
  }
}

const currentChapterIdAtom = atom('')
currentChapterIdAtom.debugLabel = 'currentChapterIdAtom'
export const useCurrentChapterIdAtom = () => {
  const [currentChapterId, setCurrentChapterId] = useAtom(currentChapterIdAtom)

  return {
    currentChapterId,
    setCurrentChapterId
  }
}

export const isEditAtom = atom(false)
isEditAtom.debugLabel = 'isEditAtom'

const editContentAtom = atom('')
editContentAtom.debugLabel = 'editContentAtom'

const editNameAtom = atom('')
editNameAtom.debugLabel = 'editNameAtom'

const storyBaseInfoAtom = atom<Record<string, unknown>>({})
storyBaseInfoAtom.debugLabel = 'storyBaseInfoAtom'

export const useStoryPageModeAtom = () => {
  const location = useLocation()
  const urlParams: ParsedQs = qs.parse(location.search.slice(1))
  const [isEdit, setIsEdit] = useAtom(isEditAtom)
  const [editContent, setEditContent] = useAtom(editContentAtom)
  const [editName, setEditName] = useAtom(editNameAtom)
  const [storyBaseInfo, setStoryBaseInfo] = useAtom(storyBaseInfoAtom)

  // 详情模式
  const isView: boolean = typeof urlParams.id !== 'undefined' && !isEdit
  // 新增模式
  const isNew: boolean = !isView && !isEdit

  const novel_id =
    urlParams.type === '1' || urlParams.type === '2' ? urlParams.id : ''
  const reel_id = urlParams.type === '3' ? urlParams.id : ''

  const story_list_id = urlParams.type === '4' ? urlParams.id : ''

  const comm_id = urlParams.type === '5' ? urlParams.id : ''

  const handleEditCheck = (currentEdit: boolean, callback: () => void) => {
    if (currentEdit) {
      Modal.confirm({
        title: 'Tip',
        content:
          'Leaving the current page unsaved data will be lost, should I continue?',
        onOk: () => {
          setIsEdit(false)
          callback()
        }
      })
    } else {
      callback()
    }
  }

  return {
    isView,
    isEdit,
    isNew,
    novel_id,
    reel_id,
    story_list_id,
    comm_id,
    setIsEdit,
    editContent,
    setEditContent,
    editName,
    setEditName,
    storyBaseInfo,
    setStoryBaseInfo,
    handleEditCheck
  }
}

const storyListIdAtom = atom('')
storyListIdAtom.debugLabel = 'storyListIdAtom'

export const useStoryListIdAtom = () => {
  const [storyListId, setStoryListIdAtom] = useAtom(storyListIdAtom)

  return {
    storyListId,
    setStoryListIdAtom
  }
}

const discussIdAtom = atom('')
discussIdAtom.debugLabel = 'discussIdAtom'

export const useDiscussIdAtom = () => {
  const [discussId, setDiscussId] = useAtom(discussIdAtom)

  return {
    discussId,
    setDiscussId
  }
}
