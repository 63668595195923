import { createContext } from 'react'

import type { TextAreaRef } from 'antd/es/input/TextArea'

export const ChatPanelInputContext = createContext<{
  textareaRef: React.RefObject<TextAreaRef> | undefined
  inputChange: (value?: string) => void
}>({
  textareaRef: undefined,
  inputChange: () => {}
})

export interface IChaModalContextType {
  onClose: () => void
}

export const ChaModalContext = createContext<IChaModalContextType>({
  onClose: function (): void {
    throw new Error('Function not implemented.')
  }
})
