import { useContext, useMemo } from 'react'

import { CloseCircleFilled } from '@ant-design/icons'
import { ConversationType } from '@rongcloud/imlib-next'
import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'

import { Personal } from '@/components/Personal'
import { IFriendItem } from '@/httpRequest/modules/imInfo/friends'
import { IGroupListItem } from '@/httpRequest/modules/imInfo/groupList'
import { useMessageAtom } from '@/store/message'
import personal from '@img/header/personal.png'
import MoreSrc from '@img/message/more.png'

import { ChaModalContext } from '../context'

export const ChatPanelHeader = () => {
  const { setGroupManage, currentConversation, userFriends, userGroups } =
    useMessageAtom()
  const { onClose } = useContext(ChaModalContext)

  const targetUserInfo = useMemo(() => {
    if (currentConversation?.conversationType === ConversationType.PRIVATE) {
      return userFriends.find(
        (friend) => friend.follower?.user_code === currentConversation?.targetId
      )
    }

    if (currentConversation?.conversationType === ConversationType.GROUP) {
      return userGroups.find(
        (group) => group.group_code === currentConversation.targetId
      )
    }
  }, [userFriends, currentConversation])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={() => setGroupManage(true)}>More</a>
    },
    {
      key: '2',
      label: <a>Report</a>
    }
  ]
  return (
    <div className="ChatPanelHeader flx-justify-between">
      {/* 用户信息 */}
      <div className="flex">
        <span
          onClick={onClose}
          style={{
            marginRight: 5,
            marginLeft: -5,
            fontSize: 16,
            cursor: 'pointer'
          }}
        >
          <CloseCircleFilled style={{ color: '#FFC585' }} />
        </span>
        <Personal
          nickname={
            (targetUserInfo as IFriendItem)?.follower?.nickname ||
            (targetUserInfo as IGroupListItem)?.name
          }
          avatar={
            (targetUserInfo as IFriendItem)?.follower?.avatar ||
            (targetUserInfo as IGroupListItem)?.avatar ||
            personal
          }
          userData={(targetUserInfo as IFriendItem)?.follower}
        />
      </div>

      {/* 菜单 */}
      <div className="flx-justify-between">
        {currentConversation?.conversationType === ConversationType.GROUP &&
        targetUserInfo ? (
          <span
            className="btn-more"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setGroupManage(true)
            }}
          >
            <img src={MoreSrc} width={5} />
          </span>
        ) : null}
      </div>
    </div>
  )
}
