import { get } from '@/httpRequest'

export interface IGetGroupMembersRequest {
  group_id: string
}

export interface IGetGroupMembersResponse {
  users: IGroupMemberItem[]
}

export interface IGroupMemberItem {
  preFix: string
  avatar: string
  nickname: string
  user_id: string
  /**
   * 角色，1群主,2管理员,3普通成员
   */
  user_role: EMemberType
  /**
   * 成员状态，1正常,2禁言
   */
  user_status: number
}

export enum EMemberType {
  /** 群主 */
  BACHELORS = 1,
  /** 管理员 */
  ADMIN = 2,
  /** 普通成员 */
  MEMBERS = 3
}

export const getGroupMembers = async (params: IGetGroupMembersRequest) => {
  const response = await get<IGetGroupMembersResponse>(
    `/im/group/members`,
    params
  )

  return response
}
