import { del } from '@/httpRequest'

export interface IGroupDismissRequest {
  /**
   * 群组id
   */
  group_id: string
}

export const delGroupDismiss = async (params: IGroupDismissRequest) => {
  const response = await del<null>(`/im/group/dismiss`, params)

  return response
}
