import { del } from '@/httpRequest'

export interface IGroupExitRequest {
  /**
   * 群id，自增id
   */
  group_id: string
  /**
   * 用户id，退出群聊的用户id，自增id
   */
  user_ids: string
}

export const delGroupExit = async (params: IGroupExitRequest) => {
  const response = await del<null>(`/im/group/exit`, params)

  return response
}
