// 瀑布流单个数据类型
export interface HomeItem {
  type: 1 | 2 | 3 | 4 | 5
}

// 页面key
export enum HomePageEnum {
  Discover = '',
  Story = 'story',
  Community = 'community',
  StoryList = 'storyList',
  Following = 'following',
  Library = 'library',
  SearchResult = 'search'
}

// 二级导航传参类型
export type NavType = 'nav' | 'rank' | 'style'

// 排版样式
export enum ListStyle {
  Grid = 1,
  Rows = 2
}

export enum EPublishType {
  Novels = '1',
  AdNovels = '2',
  Reels = '3',
  Lists = '4',
  Community = '5'
}
