import { FC } from 'react'

import imgWarnGrey from '@img/publish/warn-grey.png'

import style from './index.module.less'

export interface INoDataProps {
  tip?: string
}

const NoData: FC<INoDataProps> = (props) => {
  const { tip } = props

  return (
    <div className="flx-center flx-dir-col">
      <div className={style['publish-search-nodata']}>
        <img src={imgWarnGrey} />
        {tip ? <p>{tip}</p> : null}
      </div>
    </div>
  )
}

export default NoData
